<template>
    <h1>Registrieren</h1>
    <p>
        Prüfen Sie Ihre persönlichen Daten.
    </p>
    <div v-if="isLoading">
        <Loader/>
    </div>
    <div v-else>
        <div v-if="form.deliveryAddress">
            <p class="subheadline">Lieferadresse</p>
            <span class="is-block" v-if="form.deliveryAddress.company">{{ form.deliveryAddress.company }}</span>
            <span class="is-block" v-if="form.deliveryAddress.lastName">{{ form.deliveryAddress.lastName }}</span>
            <span class="is-block" v-if="form.deliveryAddress.firstName">{{ form.deliveryAddress.firstName }}</span>
            <span class="is-block" v-if="form.deliveryAddress.address">{{ form.deliveryAddress.address }}</span>
            <span class="is-block" v-if="form.deliveryAddress.zipCode">{{ form.deliveryAddress.zipCode }}</span>
            <span class="is-block" v-if="form.deliveryAddress.city">{{ form.deliveryAddress.city }}</span>
            <span class="is-block" v-if="form.deliveryAddress.email">{{ form.deliveryAddress.email }}</span>
            <span class="is-block" v-if="form.deliveryAddress.phone">{{ form.deliveryAddress.phone }}</span>
        </div>
        <div v-if="form.child">
            <p class="subheadline">Kind</p>
            <span class="is-block" v-if="form.child.lastName">{{ form.child.lastName }}</span>
            <span class="is-block" v-if="form.child.firstName">{{ form.child.firstName }}</span>
        </div>
        <div v-if="form.billingAddress">
            <p class="subheadline">Rechnungsadresse</p>
            <span class="is-block" v-if="form.billingAddress.company">{{ form.billingAddress.company }}</span>
            <span class="is-block" v-if="form.billingAddress.lastName">{{ form.billingAddress.lastName }}</span>
            <span class="is-block" v-if="form.billingAddress.firstName">{{ form.billingAddress.firstName }}</span>
            <span class="is-block" v-if="form.billingAddress.address">{{ form.billingAddress.address }}</span>
            <span class="is-block" v-if="form.billingAddress.zipCode">{{ form.billingAddress.zipCode }}</span>
            <span class="is-block" v-if="form.billingAddress.city">{{ form.billingAddress.city }}</span>
            <span class="is-block" v-if="form.billingAddress.email">{{ form.billingAddress.email }}</span>
            <span class="is-block" v-if="form.billingAddress.phone">{{ form.billingAddress.phone }}</span>
        </div>
        <div v-if="form.payment">
            <p class="subheadline">Zahlungsweise</p>
            <span v-if="form.payment.period===1">Wöchentlich</span>
            <span v-if="form.payment.period===2">Monatlich</span>
            <span v-if="form.payment.period===3">14-tägig</span>
            <span v-if="form.payment.type===1"> Lastschrift</span>
            <span v-if="form.payment.type===2"> Überweisung</span>
            <div v-if="form.payment.type===1 && form.bank">
                <p class="subheadline">Bankverbindung</p>
                <span class="is-block" v-if="form.bank.accountOwner">{{ form.bank.accountOwner }}</span>
                <span class="is-block" v-if="form.bank.bankName">{{ form.bank.bankName }}</span>
                <span class="is-block" v-if="form.bank.bic">{{ form.bank.bic }}</span>
                <span class="is-block" v-if="form.bank.iban">{{ form.bank.iban }}</span>
            </div>
        </div>
        <div v-if="form.deliveryInformation">
            <p class="subheadline">Informationen zur Lieferung</p>
            <span>{{ form.deliveryInformation }}</span>
        </div>
        <div v-if="form.billingPerEmail" class="subnote-send">
            <br><span>Ich möchte meine Rechnungen per E-Mail erhalten.</span>
        </div>
        <div class="subnote-send">
            <div class="button is-black is-medium is-rounded" @click="send()"><span class="has-text-weight-bold is-size-6">Registrieren</span></div>
        </div>
    </div>
    <Modal v-show="modalVisible">
        <div class="modal-message">
            <p>{{ errorText }}</p>
            <Button class="button mt-4 is-danger" aria-label="close" @click="hideModal()" text="Ok"/>
        </div>
    </Modal>
</template>

<script>
import Button from "@/views/components/ui/Button"
import Modal from "@/views/components/ui/Modal"
import Loader from "@/views/components/ui/Loader";
import errors from '@/utils/errors.json'

export default {
    name: 'RegisterOverview',
    data() {
        return {
            isLoading: false,
            modalVisible: this.$store.getters.getModalVisible,
            errorText: '',
            form: null,
            registrationType: sessionStorage.getItem('registrationType')
        }
    },
    components: {
        Button,
        Modal,
        Loader
    },
    methods: {
        getData() {
            this.form = this.$store.getters.getFormData
            this.form.registrationType = this.registrationType
        },
        hideModal() {
            this.$store.commit('TOGGLE_MODAL', false)
        },
        async send() {
            this.isLoading = true
            if (this.registrationType==='3' || this.registrationType==='4') {
                if (this.form.isNotAdult==='false') {
                    this.form.child = {}
                }
            }

            await this.$store.dispatch('postRegistration', this.form).then(() => {
                this.$parent.addStep()
            })
                .catch(() => {
                    this.errorText = errors["POST_REGISTRATION_ERROR"]
                    this.$store.commit('TOGGLE_MODAL', true)
            })
            this.isLoading = false
        }
    },
    beforeMount() {
        this.getData()
    },
    watch: {
        "$store.state.modalVisible"(bool) {
            this.modalVisible = bool;
        }
    }
}
</script>
