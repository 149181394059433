<template>
    <nav class="navbar is-flex container is-justify-content-space-between is-align-items-center py-5" role="navigation"
         aria-label="main navigation">
        <HeaderItem v-if="actualStep>0">
            <div class="back-button" @click="reduceStep()"></div>
        </HeaderItem>
        <HeaderItem class="logo" justify="center">
            <img src="@/assets/images/logo_temp.svg" alt="gourmetta logo">
        </HeaderItem>
        <HeaderItem>
            <Step :actual-step="actualStep" :max-step="3"/>
        </HeaderItem>
    </nav>
</template>

<script>
import HeaderItem from "./HeaderItem";
import Step from "@/views/components/ui/Step";

export default {
    name: 'Header',
    components: {
        HeaderItem,
        Step
    },
    data() {
        return {
            actualStep: 1
        }
    },
    methods: {
        reduceStep() {
            if (this.actualStep === 1) {
                this.$router.push('/login')
                return
            }
            this.$store.commit('REDUCE_STEP')
        }
    },
    beforeMount() {
        if (this.$store.getters.getActualStep === undefined) {
            this.actualStep = 1
            return
        }
        this.actualStep = this.$store.getters.getActualStep
    },
    watch: {
        "$store.state.actualStep"(actualStep) {
            this.actualStep = actualStep
        }
    }
}
</script>

<style lang="scss">
.navbar {
    padding-left: $gap;
    padding-right: $gap;
    box-shadow: 0 0 $shadow-blur $shadow-color;

    .back-button:after {
        border: 3px solid $primary;
        border-right: 0;
        border-top: 0;
        content: " ";
        display: block;
        height: 0.625em;
        margin-top: -0.4375em;
        position: absolute;
        transform: rotate(45deg);
        width: 0.625em;
        cursor: pointer;
    }

    .logo {
        img {
            max-height: 3em;
        }
    }

    &.container{
        min-height: 0 !important;
    }

}
</style>
