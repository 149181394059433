<template>
  <div class="container is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
    <h1 class="mb-5">Sie haben sich erfolgreich ausgeloggt.</h1>
    <Button text="Zum Login" @click="toLogin"/>
  </div>
</template>

<script>
import Button from "@/views/components/ui/Button";
export default {
  name: 'Logout',
  components: {Button},
  methods: {
    toLogin() {
      this.$router.push('login')
    }
  },
  mounted() {
    this.$store.dispatch('logout')
  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 70vh;

  h1 {
    font-weight: $font-weight-bold;
    text-align: center;
  }
}
</style>
