<template>
    <vue-collapsible-panel class="grey-container my-3" :class="{'inactive':!isActive, 'error':orderError}"
                           :expanded="expand"
                           @click="setSelectedDay">
        <template #title class="is-flex is-align-content-space-between is-align-items-center">
            <div class="date is-flex is-flex-direction-column">
                {{ getWeekDay(date) }}, {{ formattedDay }}
                <div class="day-amount-container is-flex is-align-items-center is-justify-content-space-between">
                <span class="order-number is-flex is-align-items-center" v-if="!isCart">
                    <span class="badge order-badge" v-show="orderBadge"/> {{ orders }}
                </span>
                    <span class="order-number is-flex is-align-items-center" v-if="quantityChangedMeals !== 0">
                    <span class="badge changed-badge" v-show="updateBadge"/> {{ changedOrders }}
                </span>
                </div>
            </div>
        </template>
        <template #content>
            <span class="placeholder">&nbsp;</span>
            <div class="list-header"
                 :class="{'hidden':isFilterActive}"
                 v-if="mealsLunch.length>0 && !isCart">
                <img src="@/assets/images/dish_icon.svg" alt="mittagsmenüs">
                Mittagsmenüs
            </div>
            <span v-for="meal of mealsLunch" :key="meal.id">
                <MenuListItem :meal="meal" :is-cart="isCart"/>
            </span>
            <div class="list-header"
                 :class="{'hidden':isFilterActive}"
                 v-if="mealsSalad.length>0 && !isCart">
                <img src="@/assets/images/salad_icon.svg" alt="salate & co.">
                Salate & Co.
            </div>
            <span v-for="meal of mealsSalad" :key="meal.id">
                <MenuListItem :meal="meal" :is-cart="isCart"/>
            </span>
        </template>
    </vue-collapsible-panel>
</template>

<script>
import {format, getDay, parseISO, isFuture} from 'date-fns'
import {VueCollapsiblePanel} from '@dafcoe/vue-collapsible-panel'
import MenuListItem from '@/views/components/ui/MenuListItem'

export default {
    name: 'MenuDayList',
    components: {
        VueCollapsiblePanel,
        MenuListItem
    },
    props: [
        'mealsOfTheDay',
        'date',
        'expand',
        'isCart'
    ],
    data() {
        return {
            weekDays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            formattedDay: '',
            filteredMeals: null,
            mealsLunch: [],
            mealsSalad: [],
            isActive: true,
            quantityOrderedMeals: 0,
            quantityChangedMeals: [],
            orders: '',
            changedOrders: '',
            orderBadge: true,
            updateBadge: false,
            isFilterActive: false,
            orderError: false
        }
    },
    methods: {
        getWeekDay(date) {
            const day = parseISO(date)
            return this.weekDays[getDay(day)]
        },
        checkIfActive() {
            this.isActive = false
            for (let orderedMeal of this.mealsOfTheDay.orderedMeals) {
                if (isFuture(parseISO(orderedMeal.meal.deadlineOrder))) {
                    this.isActive = true
                }
                break
            }
        },
        formatDate() {
            return format(parseISO(this.date), 'dd.MM.yyyy')
        },
        sortMeals() {
            for (let orderedMeal of this.mealsOfTheDay.orderedMeals) {
                if (typeof (orderedMeal.meal.matchingFilters) === 'undefined') {
                    continue;
                }
                if (orderedMeal.meal.matchingFilters[0] === 'Menüs') {
                    this.mealsLunch.push(orderedMeal.meal)
                }
                if (orderedMeal.meal.matchingFilters[0] === 'Salate') {
                    this.mealsSalad.push(orderedMeal.meal)
                }
            }
        },
        checkAlreadyOrderedMeals() {
            this.quantityOrderedMeals = this.mealsOfTheDay.orderedQuantity
            if (this.quantityOrderedMeals === 0) {
                this.orderBadge = false
                this.orders = 'Keine Bestellung'
                return
            }
            if (this.quantityOrderedMeals > 1) {
                this.orders = this.quantityOrderedMeals + ' Bestellungen'
                return
            }
            this.orders = this.quantityOrderedMeals + ' Bestellung'
        },
        checkMealsForChanges(weekOrderList) {
            for (let week in weekOrderList) {
                let meals = weekOrderList[week].orderDays.filter(day => day.date === this.date)
                if (meals.length === 0) {
                    continue
                }
                for (let meal of meals[0].orderedMeals) {
                    this.checkQuantityChanges(meal)
                }
            }
        },
        checkQuantityChanges(meal) {
            if (meal.quantity !== meal.orderedQuantity && !this.quantityChangedMeals.includes(meal.mealId)) {
                this.quantityChangedMeals.push(meal.mealId)
            }

            if (meal.quantity === meal.orderedQuantity && this.quantityChangedMeals.includes(meal.mealId)) {
                this.quantityChangedMeals = this.quantityChangedMeals.filter(item => item !== meal.mealId)
            }

            if (this.quantityChangedMeals.length === 0) {
                this.updateBadge = false
                this.changedOrders = ''
                return
            }

            if (this.quantityChangedMeals.length > 1) {
                this.updateBadge = true
                this.changedOrders = this.quantityChangedMeals.length + ' Änderungen'
                return
            }

            this.updateBadge = true
            this.changedOrders = this.quantityChangedMeals.length + ' Änderung'
        },
        setSelectedDay() {
            this.$store.commit('SET_SELECTED_DAY', this.date)
            this.checkIfFilterIsActive()
        },
        checkIfFilterIsActive() {
            const activeFilters = this.$store.getters.getActiveFilters

            if (activeFilters.length > 0) {
                this.isFilterActive = true
                return
            }
            this.isFilterActive = false
        },
        checkErrors(orderError) {
            if (!orderError) {
                this.showError = false
                this.errorText = ''
                this.orderError = false
                return
            }

            const errorDetails = this.$store.getters.getErrorDetails
            for (const error of errorDetails.errorCauses) {
                for (const meal of this.mealsOfTheDay.orderedMeals) {
                    if (error.mealId === meal.mealId) {
                        this.showError = true
                        this.errorText = error.errorSummary
                        this.orderError = true
                        this.$store.commit('SET_MENU_WITH_ERROR', meal.mealId)
                        return
                    }
                }
            }
            this.showError = false
            this.errorText = ''
            this.orderError = false
        }
    },
    created() {
        const weekOrderList = this.$store.getters.getWeekOrderList[this.$store.getters.getUserUuid]
        this.checkIfFilterIsActive()
        this.checkMealsForChanges(weekOrderList)
    },
    mounted() {
        this.formattedDay = this.formatDate()
        this.sortMeals()
        this.checkIfActive()
        this.checkAlreadyOrderedMeals()
        this.checkErrors(this.$store.getters.getOrderErrorStatus)
    },
    watch: {
        "$store.state.weekOrderList"(weekOrderList) {
            const orderList = weekOrderList[this.$store.getters.getUserUuid]
            this.checkMealsForChanges(orderList)
        },
        "$store.state.filters.activeFilters"() {
            this.$forceUpdate()
            this.checkIfFilterIsActive()
        },
        "$store.state.orderError"(orderError) {
            this.checkErrors(orderError)
        }
    }
}
</script>

<style lang="scss" scoped>
.vcp__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .date {
        width: 100%;

        .day-amount-container {
            flex-direction: column;

            @media all and (min-width: $mobile-xs) {
                flex-direction: row;
            }
        }
    }
}

.list-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey;
    padding: 2rem 1rem;
    font-size: $font-size-large;

    img {
        margin-right: 1em;
    }

    &.hidden {
        display: none;
    }
}

.vcp {
    &.grey-container {
        transition: $transition-standard;

        @media all and (min-width: $mobile-xs) {
            padding: 5px 0;
        }

        &.inactive {
            color: $dark-grey;

            .list-header img {
                opacity: .25;
            }
        }

        .placeholder {
            display: block;
            height: 0;
        }

        &.error {
            border: 2px solid $warning-color;
        }
    }
}

.order-number {
    font-size: $font-size-xsmall;
    color: $dark-grey;
    width: 100%;
}

.badge {
    display: block;
    width: 12px;
    height: 12px;
    margin-right: .5em;
    border-radius: 50%;
}

.changed-badge {
    background-color: $yellow;
}

.order-badge {
    background-color: $green;
}

@media all and (min-width: $desktop) {
    .desktop,
    .desktop-list {
        .list-header {
            padding: 0 1rem 1rem;
            width: 100%;
        }
    }
}
</style>
