<template>
    <div class="badge" v-show="(usersWithPendingChanges.length > 0)">{{ usersWithPendingChanges.length }}</div>
</template>

<script>
export default {
    name: 'UserBadge',
    data() {
        return {
            usersWithPendingChanges: []
        }
    },
    mounted() {
        this.usersWithPendingChanges = this.$store.getters.getUserWithPendingChanges
    },
    watch: {
        "$store.state.userList"() {
            this.usersWithPendingChanges = this.$store.getters.getUserWithPendingChanges
        }
    }
}
</script>

<style lang="scss" scoped>
.badge {
    background-color: $yellow;
    border-radius: 50%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.7rem;
    height: 1.7rem;
    font-weight: $font-weight-black;
    position: relative;
    top: -18px;
    font-size: $font-size-large;
}
</style>