<template>
    <div class="order-overview">
        <div class="container is-fluid">
            <h1>Bestellübersicht</h1>
        </div>
        <div class="columns">
            <div class="order-overview-header column is-full-mobile is-half-tablet is-one-third-desktop"
                 :class="{'is-sticky':orderOverviewHeaderIsSticky}">
                <div class="container is-fluid">
                    <p class="headline is-hidden-mobile">Ihre Einstellungen</p>
                    <p class="small-label is-hidden-mobile">Benutzer</p>
                    <UserName/>
                </div>
                <div class="datepicker-wrapper">
                    <div class="container is-fluid is-hidden-mobile">
                        <p class="small-label">Kalender</p>
                    </div>
                    <div class="datepicker-monat-wrapper">
                        <DatePicker ref="datePicker"
                            show-iso-weeknumbers
                            trim-weeks
                            is-expanded
                            v-model="range"
                            color="gray"
                            :masks="{weekdays: 'WW'}"
                            :attributes="attributes"
                            :min-date="minDate"
                            :max-date="maxDate"
                            @dayclick="onDayClick"
                            @weeknumberclick="onWeekNumberClick"
                            @update:fromPage="pageFromChange"
                        >
                            <template v-slot:header-title><b>{{ this.calendarMonth }}</b> {{ this.calendarYear }}</template>
                        </DatePicker>
                        <div class="monat" :class="{'active': this.range.length>7}" @click="activateMonth">
                            <IconMonat alt="Monat auswählen"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container is-fluid order-list column is-full-mobile is-half-tablet is-two-thirds-desktop">
                <p class="headline">Ihre Bestellungen</p>
                <div v-for="days of showList" :key="days.id">
                    <div v-if="days.date>=formatDate(this.range[0])&&days.date<=formatDate(this.range[this.range.length-1])" class="day-wrapper">
                        <div class="day">
                            <div class="day-headline">{{ getWeekDay(days.date) }}, {{ formatDateLocale(days.date) }}</div>
                            <div v-if="days.quantity>0" class="order-number is-flex is-align-items-center">
                                <span class="badge order-badge"/>{{ days.quantity }}
                                <span v-if="days.quantity===1" class="order-text">Bestellung</span>
                                <span v-else class="order-text">Bestellungen</span>
                            </div>
                            <div v-else>
                                <span class="no-order">Es liegen keine Bestellungen vor.</span>
                            </div>
                        </div>
                        <OrderOverviewList :days="days" :key="daysKey"/>
                    </div>
                </div>
                <div class="order-overview-sum is-flex is-flex-direction-column">
                    <span class="is-flex is-justify-content-space-between is-align-items-center total-price"><span class="total-price-label">Gesamtbetrag</span>{{ formatPrice(totalPrice) }} €</span>
                    <span class="is-align-self-flex-end small-info">Inkl. MwST. und Lieferkosten</span>
                    <span class="is-align-self-flex-end date-range">{{ formatRangeDate() }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { DatePicker } from 'v-calendar'
import {format, addDays, isAfter, getYear, getMonth, getDate, parseISO, getDay} from "date-fns"
import UserName from "@/views/components/ui/UserName"
import OrderOverviewList from "@/views/components/ui/OrderOverviewList"
import IconMonat from '@/assets/images/icon_monat.svg?inline'

export default {
    name: 'OrderOverview',
    components: {
        DatePicker,
        UserName,
        IconMonat,
        OrderOverviewList
    },
    methods: {
        removeActiveWeeknumber(event) {
            event.target.parentElement.parentElement.querySelector('.vc-weeknumber-content.active')?.classList.remove('active')
        },
        activateMonth(event) {
            this.$store.commit('REMOVE_ACTIVE_WEEK')
            let year = this.$refs.datePicker.$props.modelValue[0].getFullYear()
            let month = this.$refs.datePicker.$props.modelValue[0].getMonth()
            this.removeActiveWeeknumber(event)
            this.setRange(new Date(year, month, 1), new Date(year, month+1, 0))
        },
        async pageFromChange(change) {
            this.actualMonth = change.month-1

            if (this.range.length > 0 && this.range[0].getMonth() !== change.month-1 && this.range[this.range.length-1].getMonth() !== change.month-1) {
                this.setRange(new Date(change.year, change.month-1, 1), new Date(change.year, change.month, 0))
                await this.showDots()
            }
        },
        onDayClick(day) {
            this.$store.commit('REMOVE_ACTIVE_WEEK')
            this.removeActiveWeeknumber(day.event)
            this.setRange(new Date(day.year, day.month-1, day.day), new Date(day.year, day.month-1, day.day))
        },
        onWeekNumberClick(weeknumber) {
            const newDays = [];

            this.$store.commit('SET_ACTIVE_WEEK', weeknumber.weeknumber)
            this.removeActiveWeeknumber(weeknumber.event)
            weeknumber.event.target.classList.add('active')

            for (const days of weeknumber.days) {
                if (days.month === this.actualMonth+1) {
                    newDays.push(days)
                }
            }
            this.setRange(new Date(newDays[0].year, newDays[0].month-1, newDays[0].day), new Date(newDays[newDays.length-1].year, newDays[newDays.length-1].month-1, newDays[newDays.length-1].day))
        },
        async getMonthData() {
            let userUuid = this.$store.getters.getUserUuid

            if(this.$store.getters.getMonthOrderOverviewList === undefined ||
                this.$store.getters.getMonthOrderOverviewList[userUuid] === undefined
            ) {
                let result = await this.$store.dispatch('getWeekOrder', {
                    from: format(this.minDate, 'yyyy-MM-dd'),
                    to: format(this.maxDate, 'yyyy-MM-dd'),
                })
                result.orderDays.filter(day => day.quantity > 0)
                await this.$store.commit('SET_MONTH_ORDER_OVERVIEW_LIST', result)
            }

            this.monthOrderOverviewList = this.$store.getters.getMonthOrderOverviewList[userUuid]
        },
        setRange(start, end) {
            this.range = []
            for (let step = start; isAfter(addDays(end, 1), step); step = addDays(step, 1)) {
                this.range.push(step)
            }
            this.$store.commit('SET_RANGE', this.range)
        },
        showRange() {
            this.showList = []

            if (this.range.length === 1) {
                if (this.monthOrderOverviewList[format(this.range[0], 'yyyy-MM-dd')]) {
                    this.showList.push(this.monthOrderOverviewList[format(this.range[0], 'yyyy-MM-dd')])
                } else {
                    this.showList.push(
                        {
                            date: format(this.range[0], 'yyyy-MM-dd'),
                            quantity: 0
                        }
                    )
                }
            } else {
                for (let step = this.range[0]; isAfter(addDays(this.range[this.range.length-1], 1), step); step = addDays(step, 1)) {
                    if (this.monthOrderOverviewList[format(step, 'yyyy-MM-dd')]) {
                        this.showList.push(this.monthOrderOverviewList[format(step, 'yyyy-MM-dd')])
                    } else {
                        this.showList.push(
                            {
                                date: format(step, 'yyyy-MM-dd'),
                                quantity: 0
                            }
                        )
                    }
                }
            }
            this.daysKey++
        },
        showDots() {
            let start = new Date(this.range[0].getFullYear(), this.range[0].getMonth(), 1)
            let end = new Date(this.range[0].getFullYear(), this.range[0].getMonth()+1, 0)

            for (let step = start; isAfter(addDays(end, 1), step); step = addDays(step, 1)) {
                if (this.monthOrderOverviewList[format(step, 'yyyy-MM-dd')] === undefined || this.monthOrderOverviewList[format(step, 'yyyy-MM-dd')].quantity === 0) {
                    this.whiteDots.push(new Date(getYear(step), getMonth(step), getDate(step)))
                } else {
                    this.greenDots.push(new Date(getYear(step), getMonth(step), getDate(step)))
                }
            }
        },
        formatDateLocale(date) {
            return format(parseISO(date), 'dd.MM.yyyy')
        },
        formatDate(date) {
            return format(date, 'yyyy-MM-dd')
        },
        formatRangeDate() {
            if (this.range.length>0) {
                if (this.range.length === 1) {
                    return format(this.range[this.range.length - 1], 'dd.MM.yyyy')
                }

                return format(this.range[0], 'dd.MM.') + ' - ' + format(this.range[this.range.length - 1], 'dd.MM.yyyy')
            }
        },
        getWeekDay(date) {
            const day = parseISO(date)
            return this.weekDays[getDay(day)]
        },
        async calcPrices() {
            this.totalPrice = 0
            for (let step = this.range[0]; isAfter(addDays(this.range[this.range.length-1], 1), step); step = addDays(step, 1)) {
                if (this.monthOrderOverviewList[format(step, 'yyyy-MM-dd')] !== undefined) {
                    for(let meal of this.monthOrderOverviewList[format(step, 'yyyy-MM-dd')].orderedMeals) {
                        this.totalPrice += meal.quantity * meal.meal.price
                    }
                }
            }
        },
        formatPrice(price) {
            return price.toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        },
        getScroll() {
            let offsetTop = document.querySelector('.order-overview-header').getBoundingClientRect().top
            if (offsetTop < 0) {
                this.orderOverviewHeaderIsSticky = true
                return
            }
            this.orderOverviewHeaderIsSticky = offsetTop === 0;
        },
        checkActiveWeek() {
            let activeWeek = this.$store.getters.getActiveWeek

            if (activeWeek !== undefined) {
                let weeks = document.querySelectorAll('.vc-weeknumber-content')

                for (let week of weeks) {
                    if (week.innerHTML === activeWeek) {
                        week.classList.add('active')
                    }
                }
            }
        },
        setCalendarTitle() {
            this.calendarMonth = this.monthNames[this.range[0].getMonth()]
            this.calendarYear = this.range[0].getFullYear()
        }
    },
    mounted() {
        window.addEventListener('scroll', this.getScroll)
    },
    unmounted() {
        window.removeEventListener('scroll', this.getScroll)
    },
    async beforeMount() {
        await this.getMonthData()
        this.range = this.$store.getters.getRange

        if (this.range === undefined || this.range === []) {
            this.setRange(new Date(), new Date())
        }

        this.checkActiveWeek()
        this.$refs.datePicker.move(this.range[0])
        this.showRange()
        await this.showDots()
        await this.calcPrices()
    },
    watch: {
        "$store.state.monthOrderOverviewList"(monthOrderOverviewList) {
            this.monthOrderOverviewList = monthOrderOverviewList
        },
        async "$store.state.userUuid"() {
            this.greenDots = []
            this.whiteDots = []
            await this.getMonthData()
            this.setRange(
                new Date(this.range[0].getFullYear(), this.range[0].getMonth(), this.range[0].getDate()),
                new Date(this.range[this.range.length-1].getFullYear(), this.range[this.range.length-1].getMonth(), this.range[this.range.length-1].getDate()),
            )
            this.showRange()
            await this.showDots()
            await this.calcPrices()
        },
        range() {
            this.setCalendarTitle()
            this.showRange()
            this.calcPrices()
        }
    },
    computed: {
        attributes() {
            return [
                {
                    dot: 'green',
                    dates: this.greenDots
                },
                {
                    dot: {
                        style: {
                            'background-color': 'white',
                            'border': '1px solid black'
                        }
                    },
                    dates: this.whiteDots
                },
                {
                    highlight: true,
                    dates: this.range,
                }
            ]
        }
    },
    data() {
        return {
            orderOverviewHeaderIsSticky: false,
            totalPrice: 0,
            weekDays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            formattedDay: '',
            monthOrderOverviewList: [],
            showList: [],
            greenDots: [],
            whiteDots: [],
            actualMonth: new Date().getMonth(),
            calendarMonth: 0,
            calendarYear: 0,
            range: [],
            minDate: new Date(new Date().getFullYear(), new Date().getMonth()-2, 1),
            maxDate: new Date(new Date().getFullYear(), new Date().getMonth()+3, 0),
            daysKey: 0
        };
    }
}
</script>
<style lang="scss">
.order-overview {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .columns {
        margin: 0;

        .column {
            padding: 0;
        }
    }
    h1 {
        font-size: $fontsize-headline6;
        font-weight: $font-weight-bold;
        padding-bottom: 1rem;
    }
    .small-label {
        font-size: $font-size-small;
    }
    .welcome,
    .sub-welcome {
        display: none;
    }
    .user-name > span,
    .dropdown.select button {
        font-size: $font-size-medium;
    }
    .headline {
        padding-bottom: 2rem;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
    }
    .datepicker-wrapper {
        padding-top: 1rem;
        padding-bottom: 2rem;

        .datepicker-monat-wrapper {
            position: relative;
        }
        .vc-container {
            border: none;
            border-top: 1px solid $light-grey;
            border-radius: 0;
        }
        .monat {
            position: absolute;
            top: 10px;
            right: $gap;
            padding: 7px 9px 1px 9px;

            &.active {
                background-color: $primary;
                fill: $secondary;
                border-radius: 30px;
            }
        }
        .vc-popover-content {
            display: none;
        }
        .vc-header,
        .vc-arrows-container {
            width: 80%;
            padding: 1rem;
        }
        .vc-arrow {
            color: $primary;
        }
        .vc-title {
            cursor: default;

            &:hover {
                opacity: 1;
            }
        }
        .vc-weeks {
            padding: 10px $gap;
        }
        .vc-day {
            min-height: 45px;

            .vc-highlight {
                width: 31px;
                height: 31px;
                background-color: $calendar-highlight-color!important;
            }
            &.is-today .vc-highlight {
                    background-color: #41BFED!important;
            }
        }
        .vc-day-content {
            width: 31px;
            height: 31px;
            line-height: 31px;
            font-size: 19px;
            margin-top: 3px;
            font-weight: $font-weight-semi-bold!important;

            &:focus {
                color: $secondary;
                background-color: $calendar-highlight-color;
            }
        }
        .vc-day-box-center-center {
            align-items: baseline;
            margin: 3px 0 10px 0;
        }
        .vc-weekday {
            color: $primary;
            font-weight: $font-weight-normal;
            font-size: $font-size-xsmall;
        }
        .monat,
        .vc-weeknumber {
            align-items: baseline;
            cursor: pointer;
            margin-top: 3px;

            &-content {
                align-items: baseline;
                font-weight: $font-weight-normal;
                font-size: $font-size-small;
                font-style: normal;
                color: #D8D8D8;
                width: 31px;
                height: 31px;
                padding-top: 4px;

                &.active {
                    background-color: #D8D8D8;
                    color: $secondary;
                    border-radius: $radius-rounded;
                }
            }
        }
        .vc-dot {
            width: 6px;
            height: 6px;
        }
    }
    .order-list {
        &.column {
            padding-left: $gap;
            padding-right: $gap;
        }
        .day-wrapper {
            > div {
                .list-item {
                    border-bottom: 1px solid $light-grey;
                }

                &:last-child .list-item {
                    border:none;
                }
            }
            .day {
                background-color: $grey;
                padding: $gap;
                border-radius: 15px;
                border: none;
                margin-top: 1rem;

                .day-headline {
                    font-size: $font-size-medium;
                    font-weight: $font-weight-bold;
                }
                .no-order {
                    font-size: $font-size-small;
                }
                .order-number {
                    font-size: $font-size-small;
                    color: $darker-grey;

                    span.order-text {
                        margin-left: .5em;
                    }
                    .badge {
                        display: block;
                        width: 12px;
                        height: 12px;
                        margin-right: .5em;
                        border-radius: 50%;
                    }
                    .order-badge {
                        background-color: $green;
                    }
                }
            }
        }
        .order-overview-sum {
            font-size: $font-size-small;
            border-top: 1px solid $light-grey;
            padding: 1rem 0;
            margin-top: 2rem;

            .total-price {
                font-weight: $font-weight-bold;
                font-size: $font-size-xlarge;

                &-label {
                    font-size: $font-size-medium;
                }
            }
            .small-info {
                color: $darker-grey;
            }
            .date-range {
                font-weight: $font-weight-semi-bold;
            }
        }
    }
}
@media all and (min-width: $tablet) {
    .order-overview {
        max-width: none !important;
        padding-left: $gap;
        padding-right: $gap;
        width: 100%;

        h1 {
            font-size: $fontsize-headline4;
            padding: 1rem 0;
        }
        .small-label {
            padding-top: 2rem;
            border-top: 1px solid $light-grey;
        }
        .order-overview-header {
            border-right: 1px solid $light-grey;
            &.is-sticky {
                align-self: flex-start;
                position: sticky;
                top: 0;
            }
        }
        .order-list {
            .headline {
                padding-bottom: 1rem;
            }
            .day-wrapper {

                .list-item {
                    padding: 40px 0;
                    margin: 0 $gap;
                    border: none;
                }
            }
        }
        .datepicker-wrapper {
            .vc-container {
                border: none;
            }
        }
    }
}
</style>