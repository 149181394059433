<template>
    <div class="header container is-fluid">
        <UserName/>
        <MenuFilter/>
        <h1 class="menu-list-title pb-4">Speiseplan</h1>
    </div>
    <WeekSlider/>
    <DesktopToggle :desktop-list="desktopListVisible"/>
    <div class="container is-fluid" :class="desktopListVisible?'desktop-list':'desktop'">
        <div v-if="isLoading">
            <Loader/>
        </div>
        <div v-else>
            <vue-collapsible-panel-group v-if="isAccordion===true" :accordion="true"
                                         base-color="#00000000"
                                         border-color="#00000000"
                                         bg-color-header="#00000000"
                                         bg-color-header-hover="#00000000"
                                         bg-color-header-active="#00000000">
                <MenuDayList
                    v-for="dayMeals of weekMeals.orderDays"
                    :key="dayMeals.date + '-' + weekMeals.userUuid"
                    :meals-of-the-day="dayMeals"
                    :date="dayMeals.date"
                    :expand="false"
                    :is-cart="false"
                />
            </vue-collapsible-panel-group>
            <div v-else
                 class="is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-flex-start is-justify-content-space-between">
                <MenuDayList
                    v-for="dayMeals of weekMeals.orderDays"
                    :key="dayMeals.date + '-' + weekMeals.userUuid"
                    :meals-of-the-day="dayMeals"
                    :date="dayMeals.date"
                    :expand="true"
                />
            </div>
        </div>
    </div>
    <Modal v-show="modalVisible">
        <div v-if="selectedMeal!==null">
            <div class="modal-header">
                <span class="category-name">
                    {{ selectedMeal.categoryName }}
                </span>
                <span class="menu-tags" v-for="tag in selectedMeal.tags" :key="tag">
                    | {{ tag }}
                </span>
            </div>
            <p class="meal">
                <span class="meal-header">{{ selectedMeal.name }}</span>
                <span>{{ selectedMeal.description }}</span>
            </p>
            <hr>
            <p>
                <span class="headline">Information</span>
                <span>{{ selectedMeal.ingredients.map(ingredient => ingredient.name).join(', ') }}</span>
            </p>
            <p>
                <span class="headline">Zusatzstoffe</span>
                <span>{{ selectedMeal.foodAdditives.map(foodAdditive => foodAdditive.name).join(', ') }}</span>
            </p>
            <p>
                <span
                    class="headline">Stoffe o. Erzeugnisse, die Allergien oder Unverträglichkeiten auslösen können</span>
                <span>{{ selectedMeal.allergens.map(allergen => allergen.name).join(', ') }}</span>
            </p>
            <p v-if="hasDGEtag">
                <span class="headline">DGE</span>
                <span>Die Angebote mit der Kennzeichnung „DGE“ entsprechen dem „DGE-Qualitätsstandard für die Verpflegung in Schulen“ und wurden von der Deutschen Gesellschaft für Ernährung e.V. (DGE) als eine Menülinie zertifiziert.</span>
            </p>
        </div>
    </Modal>
</template>

<script>
import UserName from "@/views/components/ui/UserName";
import WeekSlider from "@/views/components/ui/WeekSlider";
import MenuDayList from "@/views/components/ui/MenuDayList";
import Loader from "@/views/components/ui/Loader";
import {VueCollapsiblePanelGroup} from '@dafcoe/vue-collapsible-panel'
import Modal from "@/views/components/ui/Modal"
import DesktopToggle from "@/views/components/ui/DesktopToggle"
import MenuFilter from "@/views/components/ui/MenuFilter";

export default {
    name: 'MenuPlan',
    components: {
        MenuFilter,
        Loader,
        MenuDayList,
        WeekSlider,
        UserName,
        VueCollapsiblePanelGroup,
        Modal,
        DesktopToggle
    },
    data() {
        return {
            weekMeals: [],
            activeUserUuid: '',
            selectedMeal: this.$store.getters['getSelectedMeal'],
            modalVisible: this.$store.getters['getModalVisible'],
            isLoading: true,
            desktopListVisible: false,
            isAccordion:true,
            viewport: 'mobile'
        }
    },
    methods: {
        hideModal() {
            this.$store.commit('TOGGLE_MODAL', false)
        },
        getIsAccordion() {
            return !(this.desktopListVisible && this.viewport === 'desktop')
        },
        getViewport() {
            if (window.outerWidth < 1000 ) {
                this.desktopListVisible = false;
                this.$store.commit('TOGGLE_DESKTOP', false)
                this.isAccordion = true;
                return 'mobile'
            }

            return 'desktop'
        },
        handleWindowResize() {
            this.viewport = this.getViewport()
        },
        async getWeekOrder() {
            this.isLoading = true
            const weekOrderList = this.$store.getters.getWeekOrderList
            const selectedWeekNumber = this.$store.getters.getWeekNumber
            const userUuid = this.$store.getters.getUserUuid

            if (weekOrderList === undefined || weekOrderList[userUuid] === undefined || weekOrderList[userUuid][selectedWeekNumber] === undefined) {
                this.weekMeals = await this.$store.dispatch('getWeekOrder', this.$store.getters.getSelectedWeek)
                this.$store.commit('SET_WEEK_ORDER_LIST', this.weekMeals)
                this.isLoading = false
                return
            }

            this.weekMeals = weekOrderList[userUuid][selectedWeekNumber]
            this.isLoading = false
        },
        showModal(meal) {
            this.$store.commit('SET_SELECTED_MEAL', meal)
            this.$store.commit('TOGGLE_MODAL', true)
        }
    },
    beforeMount() {
        this.activeUserUuid = this.$store.getters.getUserUuid
        this.desktopListVisible = this.$store.getters['getDesktopListVisible']
        this.viewport = this.getViewport()
        this.isAccordion = this.getIsAccordion()
    },
    watch: {
        "$store.state.modalVisible"(bool) {
            this.modalVisible = bool;
        },
        "$store.state.selectedMeal"(meal) {
            this.selectedMeal = meal;
        },
        "$store.state.userUuid"(userUuid) {
            this.activeUserUuid = userUuid
            this.getWeekOrder()
        },
        "$store.state.desktopListVisible"(bool) {
            this.desktopListVisible = bool
            this.isAccordion = this.getIsAccordion()
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowResize);
    },
    computed: {
      hasDGEtag() {
        return this.selectedMeal.tags.includes('DGE')
      }
    }
}
</script>

<style lang="scss">
.header {
    padding-top: $gap;

    .menu-list-title {
        font-weight: $font-weight-black;
        font-size: $font-size-large;
    }
}

.modal-content {
    hr {
        background-color: $dark-grey;
    }

    p {
        margin-bottom: 1rem;
        font-size: $font-size-small;
    }

    .modal-header {
        margin-bottom: 1rem;
        display: inline-block;

        span {
            font-size: $font-size-small;
        }

        .category-name {
            background: $primary;
            color: $secondary;
            font-weight: $font-weight-black;
            border-radius: 17px;
            padding: 5px 15px;
            margin-right: .25rem;
        }
    }

    .meal {
        .meal-header {
            display: block;
            font-weight: $font-weight-black;
            font-size: $font-size-large;
        }
    }

    .headline {
        display: block;
        font-weight: $font-weight-bold;
    }
}

@media all and (min-width: $desktop) {
    .desktop-list {
        display: flex;
        justify-content: space-between;

        section {
            width: 14%;
            max-width: 14%;
            flex: 0 0 auto;

            .vcp__body {
                overflow: visible;
            }

            .vcp__header {
                text-align: center;
                font-size: $font-size-xsmall;

                .vcp__header-title{
                    justify-content: center;

                    .date,
                    .day-amount-container {
                        display: block !important;
                    }
                    .date {
                        text-align: center;
                        .day-amount-container {
                            height: 50px;
                            .order-number {
                                font-size: $font-size-xxsmall;
                                display: inline-block !important;
                                line-height: 20px;
                                .badge{
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-right: 0.1rem;
                                    width: 8px;
                                    height: 8px;
                                }
                            }
                        }
                    }
                }
                .vcp__header-icon {
                    display: none !important;
                }

            }
            .vcp__body-content {
                padding: 5px;

                .menu-list-item {
                    padding: 10px 5px;

                    .menu-list-item__header {
                        font-size: $font-size-xxsmall;
                        height: auto ;
                    }
                    .menu-list-item__name {
                        font-size: $font-size-xxsmall;
                        font-weight: $weight-bold;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        height:30px;
                    }
                    .menu-list-item__description {
                        height: 45px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        font-size: $font-size-xxsmall;
                    }
                    .menu-list-item__allergens {
                        font-size: $font-size-xxsmall;
                        padding-bottom: 5px;
                    }
                    .amount-container {
                        .price {
                            display: block;
                            text-align: center;
                            width: 100%;
                            padding-bottom: 5px;
                        }
                        .button-container {
                            width: 100%;
                            input {
                                width: 100%;
                            }
                        }
                        .error {
                            position: absolute;
                            width: 150px;
                            height: 75px;
                            background: red;
                            border: 1px solid red;
                            font-size: 12px;
                            top: 80px;
                            color: white;
                            padding: 10px;
                            left: 0;
                            z-index: 1000;
                            border-radius: $border-radius;
                            -webkit-animation: hide 1s forwards;
                            -webkit-animation-iteration-count: 1;
                            -webkit-animation-delay: 2s;
                            animation: hide 1s forwards;
                            animation-iteration-count: 1;
                            animation-delay: 2s;
                        }
                        @-webkit-keyframes hide {
                            0% {
                                opacity: 1;
                            }
                            100% {
                                opacity: 0;
                            }
                        }
                        @keyframes hide {
                            0% {
                                opacity: 1;
                            }
                            100% {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
            &.vcp--expanded {
                .vcp__header {
                    font-weight: 500;
                }
            }
            .list-header {
                display: none;
            }
        }

    }
}

@media all and (min-width: $widescreen) {
    .desktop-list {
        section {
            .vcp__header {
                font-size: $font-size-medium;
                .vcp__header-title{
                    .date {
                        .day-amount-container {
                            .order-number {
                                font-size: $font-size-xsmall;
                                .badge{
                                    width: 12px;
                                    height: 12px;
                                }
                            }
                        }
                    }
                }
        }
            .vcp__body-content {
                .menu-list-item {
                    .menu-list-item__name {
                        height:40px;
                        font-size: $font-size-xsmall;
                    }
                    .menu-list-item__description {
                        height: 60px;
                        font-size: $font-size-xsmall;
                    }
                    .menu-list-item__header {
                        font-size: $font-size-xsmall;
                        height: auto ;
                    }
                    .menu-list-item__allergens {
                        font-size: $font-size-xxsmall;
                        padding-bottom: 5px;
                    }
                }
            }
        }

    }
}

@media all and (min-width: $fullhd) {
    .desktop-list {
        section {
            .vcp__header {
                .vcp__header-title{
                    .date {
                        width: 80%;
                    }
                }
            }
            .vcp__body-content {
                .menu-list-item {
                    .menu-list-item__description {
                        height: 60px;
                    }
                }
            }
        }

    }
}
</style>
