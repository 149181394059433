<template>
    <footer class="footer ">
        <div class="container is-flex is-justify-content-space-between">
            <router-link to="/speiseplan">
                <div class="footer-item">
                    <IconMenuPlan />
                    <span>Speiseplan</span>
                </div>
            </router-link>
            <router-link to="/warenkorb">
                <div class="footer-item" :class="{'cart-badge':usersWithPendingChanges.length>0}">
                    <IconChart />
                    <span>Warenkorb</span>
                </div>
            </router-link>
            <div class="footer-item" @click="openSidebar">
                <IconMenu />
                <span>Menü</span>
            </div>
        </div>
    </footer>
</template>

<script>
import IconMenuPlan from '@/assets/images/icon_menuplan.svg?inline'
import IconChart from '@/assets/images/icon_chart.svg?inline'
import IconMenu from '@/assets/images/icon_menu.svg?inline'

export default {
    name: 'FunctionalFooter',
    components: {
        IconMenuPlan,
        IconChart,
        IconMenu
    },
    data() {
        return {
            usersWithPendingChanges: []
        }
    },
    methods: {
        checkUsersWithChanges() {
            let userList = this.$store.getters.getUserList

            if (userList) {
                this.populateUserList(userList)

                for (let user of userList.accounts) {
                    this.populateUserList(user)
                }

                this.$store.commit('SET_USER_WITH_PENDING_CHANGES', this.usersWithPendingChanges)
            }
        },
        populateUserList(user) {
            if (user.modifiedMeals !== undefined && user.modifiedMeals.length > 0) {
                if (!this.usersWithPendingChanges.includes(user.uuid)) {
                    this.usersWithPendingChanges.push(user.uuid)
                }
            }

            if (user.modifiedMeals === undefined || user.modifiedMeals.length === 0) {
                this.usersWithPendingChanges = this.usersWithPendingChanges.filter(item => item !== user.uuid)
            }
        },
        openSidebar() {
            this.$store.commit('TOGGLE_SIDEBAR')
        }
    },
    beforeMount() {
        this.checkUsersWithChanges()
    },
    watch: {
        "$store.state.userList"() {
            this.checkUsersWithChanges()
        }
    }
}
</script>

<style lang="scss" scoped>
footer.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 39;
    padding: 0;
    background: none;

    .container {
        padding: 16px 20px 32px;
        padding-bottom: max(32px, env(safe-area-inset-bottom));
        background-color: $secondary;
        min-height: none !important;
        box-shadow: 0 0 $shadow-blur $shadow-color;
    }

    .router-link-active {
        background-color: $light-grey;
        border-radius: 15px;
    }

    .footer-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        cursor: pointer;
        padding: .5rem;
        width: 80px;

        span {
            font-weight: $font-weight-bold;
            font-size: $font-size-xsmall;
            margin-top: 3px;
        }

        &.cart-badge {
            &:after {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: -10px;
                width: 1rem;
                height: 1rem;
                background-color: $yellow;
                border-radius: 50%;
            }
        }

    }
}
</style>
