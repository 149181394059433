const getters = {
    isLoggedIn(state) {
        if(localStorage.getItem('isLoggedIn')) {
            return localStorage.getItem('isLoggedIn')
        } else {
            return state.login
        }
    },
    getJwt(state) {
        if(localStorage.getItem('jwt')) {
            return localStorage.getItem('jwt')
        } else {
            return state.jwt
        }
    },
    getSelectedDay(state) {
        let selectedDay = new Date(state.day)
        return selectedDay.getFullYear() + '-' + ("0" + (selectedDay.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDay.getDate())).slice(-2)
    },
    getSelectedMeal(state) {
        return state.selectedMeal
    },
    getUserUuid(state) {
        if(localStorage.getItem('userUuid')) {
            return localStorage.getItem('userUuid')
        }
        return state.userUuid
    },
    getUserList(state) {
        if(localStorage.getItem('userList')) {
            return JSON.parse(localStorage.getItem('userList'))
        }
        return state.userList
    },
    getSelectedWeek(state) {
        if(localStorage.getItem('selectedWeek')) {
            return JSON.parse(localStorage.getItem('selectedWeek'))
        }
        return state.selectedWeek
    },
    getWeekNumber(state) {
        if(localStorage.getItem('weekNumber')) {
            return JSON.parse(localStorage.getItem('weekNumber'))
        }
        return state.weekNumber
    },
    getModalVisible(state) {
        return state.modalVisible
    },
    getWeekOrderList(state) {
        // TODO: maybe the logic that now is in MenuPlan.vue (see method getWeekOrder()) to check if a menu already saved is can eventually here happen. In this way we can save some code in components and pack the whole logic in one place.
        if(localStorage.getItem('weekOrderList')) {
            return JSON.parse(localStorage.getItem('weekOrderList'))
        }
        return state.weekOrderList
    },
    getActiveFilters(state) {
        if (localStorage.getItem('activeFilters')) {
            return JSON.parse(localStorage.getItem('activeFilters'))
        }

        return state.filters.activeFilters
    },
    getMenuFilterStatus(state) {
        if (localStorage.getItem('isMenuActive')) {
            return JSON.parse(localStorage.getItem('isMenuActive'))
        }

        return state.filters.isMenuActive
    },
    getSaladFilterStatus(state) {
        if (localStorage.getItem('isSaladActive')) {
            return JSON.parse(localStorage.getItem('isSaladActive'))
        }

        return state.filters.isSaladActive
    },
    getOrders(state) {
        if (localStorage.getItem('orders')) {
            return JSON.parse(localStorage.getItem('orders'))
        }

        return state.orders
    },
    getDesktopListVisible(state) {
        return state.desktopListVisible
    },
    getErrorDetails(state) {
        if (localStorage.getItem('errorDetails')) {
            return JSON.parse(localStorage.getItem('errorDetails'))
        }

        return state.errorDetails
    },
    getOrderErrorStatus(state) {
        if (localStorage.getItem('orderError')) {
            return JSON.parse(localStorage.getItem('orderError'))
        }
        return state.orderError
    },
    getUserWithPendingChanges(state) {
        if (localStorage.getItem('userWithPendingChanges')) {
            return JSON.parse(localStorage.getItem('userWithPendingChanges'))
        }
        return state.userWithPendingChanges
    },
    getMenusWithError(state) {
        if (localStorage.getItem('errorMenus')) {
            return JSON.parse(localStorage.getItem('errorMenus'))
        }

        return state.errorMenus
    },
    getActualStep(state) {
        if (sessionStorage.getItem('actualStep')) {
            return parseInt(sessionStorage.getItem('actualStep'))
        }
        return state.actualStep
    },
    getFormData() {
        let formName = 'form'+sessionStorage.getItem('registrationType')

        if (sessionStorage.getItem(formName)) {
            return JSON.parse(sessionStorage.getItem(formName))
        }
        return false
    },
    getSidebarStatus(state) {
        return state.sidebarActive
    },
    getDessertOrSideDish() {
        let savedWeekOrderList = getters.getWeekOrderList()
        let selectedWeek = getters.getWeekNumber()
        let activeUser = getters.getUserUuid()
        let dayDate = getters.getDayDate()

        for (let day of savedWeekOrderList[activeUser][selectedWeek].orderDays) {
            if (day.date === dayDate) {
                return day.dessertOrSideDish
            }
        }
    },
    getDayDate(state) {
        if (localStorage.getItem('dayDate')) {
            return localStorage.getItem('dayDate')
        }

        return state.dayDate
    },
    getMonthOrderOverviewList(state) {
        if(localStorage.getItem('monthOrderOverviewList')) {
            return JSON.parse(localStorage.getItem('monthOrderOverviewList'))
        }
        return state.monthOrderOverviewList
    },
    getRange(state) {
        if(localStorage.getItem('range')) {
            let range = JSON.parse(localStorage.getItem('range'))
            let newRange = []

            for (const day of range) {
                newRange.push(new Date(day))
            }
            return newRange
        }
        return state.range
    },
    getActiveWeek() {
        return localStorage.getItem('activeWeek')
    }
}

export default getters