<template>
    <div v-if="actualStep<=maxStep">
        <strong>{{ actualStep }}</strong> / <span>{{ maxStep }}</span>
    </div>
</template>

<script>
export default {
    name: 'Step',
    props: [
        'actualStep',
        'maxStep'
    ]
}
</script>

<style lang="scss" scoped>
div {
    strong,
    span {
        font-size: $font-size-large
    }
}
</style>