<template>
    <div class="filter-item is-flex is-flex-direction-column"
         :class="(isActive) ? name + ' active' : name"
         @click="setFilter"
         :ref="title"
    >
        <component :is="icon" class="filter-item__icon"/>
        <span class="filter-item__category-name">{{ title }}</span>
    </div>
</template>

<script>
export default {
    name: 'FilterItem',
    props: [
        'icon',
        'title',
        'name'
    ],
    data() {
        return {
            isActive: false,
        }
    },
    methods: {
        toggleMenuAndSaladFilters(filter) {
            const isSaladActive = this.$store.getters.getSaladFilterStatus
            const isMenuActive = this.$store.getters.getMenuFilterStatus

            if (filter === 'Menüs') {
                this.$store.commit('SET_MENU_FILTER', !isMenuActive)
                if (isSaladActive) {
                    this.isActive = false
                    this.$store.commit('SET_SALAD_FILTER', false)
                }
                return
            }

            if (filter === 'Salate') {
                this.$store.commit('SET_SALAD_FILTER', !isSaladActive)
                if (isMenuActive) {
                    this.isActive = false
                    this.$store.commit('SET_MENU_FILTER', false)
                }
            }
        },
        setFilter() {
            this.$store.commit('SET_ACTIVE_FILTER', this.name)
            this.toggleMenuAndSaladFilters(this.name)
            this.checkActiveStatus()
        },
        checkActiveStatus() {
            const activeFilters = this.$store.getters.getActiveFilters

            if (activeFilters.includes(this.name)) {
                this.isActive = true
                return
            }

            this.isActive = false
        }
    },
    created() {
        this.subscribe = this.$store.subscribe((mutation) => {
            if (mutation.type === 'SET_ACTIVE_FILTER') {
                this.checkActiveStatus()
            }
        })
    },
    mounted() {
        this.checkActiveStatus()
    },
    updated() {
        this.checkActiveStatus()
    },
    beforeUnmount() {
        this.subscribe()
    }
}
</script>

<style lang="scss" scoped>
.filter-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;
    font-size: 0.5rem;
    font-weight: $font-weight-bold;
    width: 23%;
    padding: 5px;
    position: relative;
    text-align: center;
    margin-bottom: 1.2rem;
    background-color: $grey-lightest;
    border-radius: 9px;

    @media only screen and (min-width: 322px) {
        padding: 10px;
        font-size: $font-size-xxsmall;
    }

    @media only screen and (min-width: 420px) {
        font-size: $font-size-xsmall;
    }

    @media only screen and (min-width: $desktop) {
        width: 12%;
    }

    &:hover {
        cursor: pointer;
    }

    svg {
        flex: 1 1 auto;
        align-self: center;
        max-height: 60px;
        width: 100%;

        @media only screen and (min-width: 330px) {
            margin-bottom: .5rem;
        }

        @media only screen and (min-width: 420px) {
            transform: none;
            width: 80%;
        }
    }

    &.XXL {
        svg {
            max-height: 40px;
        }
    }

    &__category-name {
        position: relative;
        bottom: 0;
        flex: 0 1 auto;
        align-self: auto;
    }

    &.alles {
        border: 1px solid $primary;
    }

    &:not(alles) {
        &.active {

            background-color: $primary;
            color: $secondary;

            svg {
                fill: $secondary;
            }
        }
    }

}
</style>
