<template>
    <div class="container is-fluid desktop-toggle">
        <span @click="showDesktopList(false, 'Kachelansicht')" class="icon-desktop-card-view"
              :class="{'active':!desktopList}" title="Kachelansicht">
            <img src="@/assets/images/desktop_card_view.svg" alt="Kachelansicht" title="Kachelansicht">
        </span>
        <span @click="showDesktopList(true, 'Spaltenansicht')" class="icon-desktop-list_view"
              :class="{'active':desktopList}" title="Spaltenansicht">
            <img src="@/assets/images/desktop_column_view.svg" alt="Spaltenansicht" title="Spaltenansicht">
        </span>
    </div>
</template>

<script>
export default {
    name: 'DesktopToggle',
    props: ['desktopList'],
    data() {
        return {
            desktopListVisible: false,
        }
    },
    methods: {
        showDesktopList(bool, eventType) {
            if (this.$store.getters.getDesktopListVisible === bool) {
                return
            }
            this.$store.commit('TOGGLE_DESKTOP', bool)
            this.googleAnalyticsTrackEvent(eventType)
        },
        googleAnalyticsTrackEvent(eventType) {
            this.$gtag.event('Desktop Toggle', {
                'event_category': 'Desktop Ansicht',
                'event_label': eventType
                })
        }
    },
    watch: {
        "$store.state.desktopListVisible"(bool) {
            this.desktopListVisible = bool;
        },

    },
    beforeMount() {
        this.desktopListVisible = this.$store.getters['getDesktopListVisible']
    }
}
</script>

<style lang="scss" scoped>
.desktop-toggle {

    display: none;
    @media (min-width: $desktop) {
       display: block;
    }
    span {
        display: inline-block;
        height: 30px;
        cursor: pointer;

        img {
            height: 30px;
        }

        &.active {
            background: $light-grey;
            border-radius: 6px;
        }

        &.icon-desktop-card-view {
            padding: 0 3px;
            margin-right: 3px;
        }

        &.icon-desktop-list_view{
            margin-left: 3px;
            padding: 0 3px;
            position: relative;
            &::after {
                background: $yellow;
                content: 'Beta';
                font-size: $font-size-xxsmall;
                line-height: $font-size-xxsmall;
                color: black;
                position: absolute;
                top: 0px;
                left: 25px;
                display: block;
                border-radius: $border-radius;
                padding: 3px 6px;
                text-align: center;
            }
        }
    }
}
</style>
