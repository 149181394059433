<template>
    <footer class="footer container is-flex is-flex-wrap-wrap is-justify-content-space-between pb-0">
        <div class="footer-content mb-4">
            <span class="title is-6 mb-2 mt-5">Unternehmen</span>
            <ul>
                <li><a href="https://gourmetta.de/unsere-bereiche/menueservice/" target="_blank">Menüservice</a></li>
                <li><a href="https://gourmetta.de/unsere-bereiche/schulverpflegung/" target="_blank">Schulverpflegung</a></li>
                <li><a href="https://gourmetta.de/unsere-bereiche/restaurants/" target="_blank">Restaurants</a></li>
                <li><a href="https://gourmetta.de/unsere-bereiche/catering/" target="_blank">Catering</a></li>
                <li><a href="https://gourmetta.de/unsere-bereiche/produktion/" target="_blank">Produktion</a></li>
            </ul>
        </div>
        <div class="footer-content mb-4">
            <span class="title is-6 mb-2 mt-5">Informationen</span>
            <ul>
                <li><a href="https://gourmetta.de/datenschutz/" target="_blank">Datenschutz</a></li>
                <li><a href="https://gourmetta.de/agb/" target="_blank">AGB</a></li>
                <li><a href="https://gourmetta.de/impressum/" target="_blank">Impressum</a></li>
                <li><span @click="showCookieLayer">Cookie-Einstellungen</span></li>
                <li><a href="https://gourmetta.de/beruf-und-karriere/" target="_blank">Beruf und Karriere</a></li>
                <li><a href="https://gourmetta.de/presse/" target="_blank">Presse</a></li>
            </ul>
        </div>
        <div class="footer-content mb-4">
            <span class="title is-6 mb-2 mt-5">Mein Gourmetta</span>
            <ul>
                <li><a href="https://gourmetta.de/kontakt/" target="_blank">Kontakt zu gourmetta</a></li>
                <li><a href="https://gourmetta.de/newsletter/" target="_blank">Newsletter</a></li>
                <li><a href="https://gourmetta.de/mypaycard/" target="_blank">MyPaycard</a></li>
                <li><a href="https://my.gourmetta.de/" target="_blank">Anmelden</a></li>
                <li><a href="https://gourmetta.de/formulare/" target="_blank">Formulare</a></li>
            </ul>
        </div>
    </footer>
</template>

<script>
export default {
    methods: {
        showCookieLayer() {
            this.$store.commit('TOGGLE_COOKIE_LAYER', true)
        }
    }
}
</script>

<style lang="scss" scoped>
.footer.is-flex {
    background-color: $background-color-dark;
    width: 100%;
    padding-top: 1rem;

    span {
        color: $secondary;
        font-weight: $font-weight-bold;
        display: flex;
    }

    ul {
        li {
            margin-bottom: .2rem;

            a {
                color: $secondary;
                font-weight: $font-weight-lighter;
            }
            span {
                cursor: pointer;
                font-weight: $font-weight-normal;
            }
        }
    }
}
</style>
