<template>
    <div class="container is-fluid change-password list-container">
        <h1>Passwort Ändern</h1>
        <p class="description">
            Wählen Sie mindestens 8 Zeichen. Verwenden Sie kein Passwort von einer anderen Website
            und kein einfach zu erratendes Passwort wie der Name Ihres Haustiers.
        </p>
        <div class="columns">
            <div class="field column is-one-quarter-desktop">
                <label class="label">Altes Passwort</label>
                <div class="control">
                    <input class="input" type="password" placeholder="Altes Passwort eingeben" v-model="oldPassword">
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="field column is-one-quarter-desktop">
                <label class="label">Neues Passwort</label>
                <div class="control">
                    <input class="input" type="password" placeholder="Neues Passwort eingeben" v-model="newPassword">
                </div>
            </div>
            <div class="field column is-three-quarters-desktop">
                <label class="label">Neues Passwort bestätigen</label>
                <div class="control">
                    <input class="input" type="password" placeholder="Neues Passwort bestätigen"
                           v-model="newPasswordRepeat">
                </div>
            </div>
        </div>
        <div v-if="isLoading">
            <Loader class="password-loader"/>
        </div>
        <div v-else>
            <Button class="change-password-button" text="Passwort ändern" @click="changePassword"/>
        </div>
    </div>
    <Modal class="change-password-modal" v-if="isModalVisible">
        <p>{{ modalText }}</p>
        <Button text="Ok" @click="hideModal" :class="{'is-success':!error, 'is-danger':error}"/>
    </Modal>
</template>

<script>
import Button from "@/views/components/ui/Button"
import errors from '@/utils/errors.json'
import Loader from "@/views/components/ui/Loader"
import Modal from "@/views/components/ui/Modal";

export default {
    name: 'ChangePassword',
    data() {
        return {
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
            modalText: '',
            error: false,
            isLoading: false,
            isModalVisible: false
        }
    },
    components: {Loader, Button, Modal},
    methods: {
        validatePassword() {
            if (this.newPassword.length < 8) {
                this.error = true
                this.isModalVisible = true
                this.modalText = errors["NEW_PASSWORD_MIN_8_CHARS"]
                return false
            }

            if (this.newPassword !== this.newPasswordRepeat) {
                this.error = true
                this.isModalVisible = true
                this.modalText = errors["NEW_PASSWORD_NOT_EQUAL"]
                return false
            }

            if (this.newPassword[0] === '#') {
                this.error = true
                this.isModalVisible = true
                this.modalText = errors["NEW_PASSWORD_FIRST_NO_HASHTAG"]
                return false
            }

            this.error = false
            return true
        },
        async changePassword() {
            let payload = {
                'oldPassword': this.oldPassword,
                'newPassword': this.newPassword,
                'newPasswordRepeat': this.newPasswordRepeat
            }

            if (!this.validatePassword()) {
                return
            }

            this.isLoading = true
            this.$store.dispatch('changePassword', payload)
                .then(() => {
                    this.error = false
                    this.isModalVisible = true
                    this.modalText = 'Ihr Passwort wurde erfolgreich geändert!'
                    this.loading = false
                })
                .catch(error => {
                    this.modalText = errors[error.errorSummary]
                    this.error = true
                    this.isModalVisible = true
                    this.isLoading = false
                })
        },
        hideModal() {
            if (this.error) {
                this.isModalVisible = false
                this.oldPassword = ''
                this.newPassword = ''
                this.newPasswordRepeat = ''
                return
            }

            this.isModalVisible = false
            this.$router.push('/kundenkonto')
        }
    }
}
</script>

<style lang="scss" scoped>
.change-password {
    padding: 1rem 0 3rem;
    color: $primary;

    h1 {
        font-size: $fontsize-headline5;
        font-weight: $font-weight-black;
    }

    .description {
        font-size: $font-size-subtitle;
        font-weight: $font-weight-bold;
        margin-bottom: 2rem;
        line-height: 1.4rem;
    }

    .error {
        color: $warning-color;
        font-size: $font-size-small;
    }

    .password-loader {
        max-height: 1rem;
    }

    .columns {
        margin-bottom: 1rem;
    }

    .field {
        margin-bottom: 0;

        .label {
            color: $primary;
        }

        .input {
            border: none;
            background-color: $grey-lightest;
            box-shadow: none;
            color: $primary;
            font-size: $font-size-small;
            max-width: 300px;
        }
    }

    .change-password-button {
        width: 100%;
        margin-top: 2rem;

        @media all and (min-width: $tablet) {
            width: 300px;
        }
    }
}

.change-password-modal {
    text-align: center;

    p {
        margin: 1rem 0;
    }
}
</style>