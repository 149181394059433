<template>
    <div v-if="isButton">
        <span class="sidebar-item">{{ text }}</span>
    </div>
    <div v-else>
        <router-link class="sidebar-item" :to="to">{{ text }}</router-link>
    </div>
</template>

<script>
export default {
    name: 'SidebarItem',
    props: [
        'text',
        'to',
        'isButton'
    ]
}
</script>

<style lang="scss" scoped>
div {
    width: 100%;
}
.sidebar-item {
    display: block;
    width: 100%;
    border-radius: 500px;
    padding: .5rem 1rem;
    margin-bottom: .3rem;
    cursor: pointer;

    &:hover,
    &.router-link-active,
    &.router-link-exact-active {
        background-color: $grey;
    }
}
</style>