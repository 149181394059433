<template>
    <div class="category-accordion mb-3">
        <vue-collapsible-panel-group accordion>
            <vue-collapsible-panel :expanded="false">
                <template #title>
                    <span class="accordion-title">Kategorien</span>
                </template>
                <template #content>
                    <FilterItem
                        v-for="filter of filters"
                        :key="filter.title"
                        :icon="filter.icon"
                        :title="filter.title"
                        :name="filter.name"
                    />
                </template>
            </vue-collapsible-panel>
        </vue-collapsible-panel-group>
    </div>
</template>

<script>
import {VueCollapsiblePanelGroup} from '@dafcoe/vue-collapsible-panel'
import {VueCollapsiblePanel} from '@dafcoe/vue-collapsible-panel'
import FilterItem from "@/views/components/ui/FilterItem";
import IconMenuFilter from "@/assets/images/filter_menu.svg?inline"
import IconSaladsFilter from "@/assets/images/filter_salad.svg?inline"
import IconOffersFilter from "@/assets/images/filter_offers.svg?inline"
import IconVeggieFilter from "@/assets/images/filter_veggie.svg?inline"
import IconVeganFilter from "@/assets/images/filter_vegan.svg?inline"
import IconFitMeFilter from "@/assets/images/filter_fitness.svg?inline"
import IconXXLFilter from "@/assets/images/filter_xxl.svg?inline"
import IconAllFilter from "@/assets/images/filter_all.svg?inline"
import {shallowRef} from 'vue'

export default {
    name: 'MenuFilter',
    components: {
        FilterItem,
        VueCollapsiblePanelGroup,
        VueCollapsiblePanel
    },
    data() {
        return {
            filters: [
                {
                    title: 'Menüs',
                    name: 'Menüs',
                    icon: shallowRef(IconMenuFilter)
                },
                {
                    title: 'Salate & Co.',
                    name: 'Salate',
                    icon: shallowRef(IconSaladsFilter)
                },
                {
                    title: 'Aktion',
                    name: 'Aktionen',
                    icon: shallowRef(IconOffersFilter)
                },
                {
                    title: 'Veggie',
                    name: 'Veggie',
                    icon: shallowRef(IconVeggieFilter)
                },
                {
                    title: 'Vegan',
                    name: 'Vegan',
                    icon: shallowRef(IconVeganFilter)
                },
                {
                    title: 'FitMe',
                    name: 'FitMe',
                    icon: shallowRef(IconFitMeFilter)
                },
                {
                    title: 'XXL',
                    name: 'XXL',
                    icon: shallowRef(IconXXLFilter)
                },
                {
                    title: 'Alles aus',
                    name: 'Alles',
                    icon: shallowRef(IconAllFilter)
                }
            ],
            isMenuActive: false,
            isSaladActive: false
        }
    }
}
</script>

<style lang="scss">
.category-accordion {
    .accordion-title {
        color: $primary;
        font-weight: $font-weight-black;
        font-size: $font-size-large;
    }

    .vcp--expandable {
        .vcp__header {
            padding: 15px 0;
        }

        .vcp__body-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}
</style>
