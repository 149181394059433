<template>
    <div class="list-item is-flex is-flex-direction-column">
        <span class="cat-short">{{ meal.meal.categoryShortName }}</span>
        <span>{{ meal.meal.name }}</span>
        <Price :price="meal.meal.price" :with-piece="true"/>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
            <span>Menge: {{ meal.quantity }}</span>
            <span class="total-price">{{ totalPrice }} €</span>
        </div>
    </div>
</template>

<script>
import Price from "@/views/components/ui/Price"

export default {
    name: 'OrderOverviewListItem',
    props: [
        'meal'
    ],
    components: {
        Price
    },
    data() {
        return {
            totalPrice: 0.0
        }
    },
    methods: {
        calcTotalPrice() {
            this.totalPrice = (this.meal.meal.price * this.meal.quantity).toFixed(2).replace('.',',')
        }
    },
    mounted() {
        this.calcTotalPrice()
    }
}
</script>

<style lang="scss" scoped>
.list-item {
    padding: $gap;
    border-top: 1px solid $light-grey;

    &:first-child {
        border: none;
    }

    div {
        line-height: 1.2;
    }

    span {
        font-size: $font-size-small;

        &.total-price,
        &.cat-short {
            font-size: $font-size-medium;
            font-weight: $font-weight-bold;
        }
    }
}
</style>