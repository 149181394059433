<template>
    <div class="button-container is-flex is-justify-content-space-between"
         :class="{
            'ordered':isOrdered,
            'changed':isChanged,
            'inactive':meal.locked||checkDeadline(meal.deadlineOrder),
            'error':orderError
        }"
        >
        <img src="@/assets/images/minus.svg"
             alt="remove order"
             class="quantity-button"
             :class="{'inactive':!removeQuantityButtonActive}"
             @click="removeQuantity"
        >
        <input v-model.number="quantity"
               :placeholder="quantity"
               type="number"
               :readonly="meal.locked||checkDeadline(meal.deadlineOrder)"
               @keyup.enter="updateQuantity(quantity)"
               max="99"
               min="0"
               oninput="validity.valid||(value='');"
               @blur="updateQuantity(quantity)"
        >
        <img src="@/assets/images/plus.svg"
             alt="add order"
             class="quantity-button"
             :class="{'inactive':!addQuantityButtonActive}"
             @click="addQuantity"
        >
    </div>
    <span class="error mt-2" v-if="showError">{{ errorText }}</span>
</template>

<script>
import {isPast, parseISO} from "date-fns"
import errors from '@/utils/errors.json'

export default {
    name: 'QuantityButton',
    props: [
        'meal'
    ],
    data() {
        return {
            quantity: 0,
            orderedQuantity: 0,
            isOrdered: false,
            isChanged: false,
            selectedWeekNumber: 0,
            addQuantityButtonActive: true,
            removeQuantityButtonActive: true,
            showError: false,
            errorText: '',
            orderError: false
        }
    },
    computed: {
      isDessertOrSideDish() {
          return this.meal.categoryShortName === 'BS' || this.meal.categoryShortName === 'D'
      }
    },
    methods: {
        checkDeadline(deadline) {
            return isPast(parseISO(deadline))
        },
        addQuantity() {
            const id = this.meal.id
            const userUuid = this.$store.getters.getUserUuid

            this.$store.commit('SET_DAY_DATE', this.meal.date)

            if (this.meal.locked || this.checkDeadline(this.meal.deadlineOrder)) {
                return
            }


            if (this.isDessertOrSideDish) {
                if (this.$store.getters.getDessertOrSideDish) {
                    this.errorText = errors["MESSAGE_MEAL_GROUP_LIMIT"]
                    this.showError = true
                    return
                }

                this.$store.commit('SET_DESSERT_SIDEDISH_FILTER', true)
            }

            if (this.quantity < 99) {
                if (!this.meal.multipleOrders && this.quantity === 1) {
                    this.addQuantityButtonActive = false
                    this.errorText = errors["MAX_QUANTITY_ONE"]
                    this.showError = true
                    return
                }

                this.quantity += 1
                this.showError = false
                this.errorText = ''

                if (!this.meal.multipleOrders || this.isDessertOrSideDish) {
                    this.addQuantityButtonActive = false
                }
                this.removeQuantityButtonActive = true

                this.updateQuantity(this.quantity)

                if (this.quantity === 99) {
                    this.addQuantityButtonActive = false
                }

                if (this.quantity === this.orderedQuantity) {
                    this.isChanged = false
                    this.$store.commit('REMOVE_USER_CHANGES', {
                        userUuid: userUuid,
                        mealId: id
                    })
                    return
                }
                this.isChanged = true
                this.$store.commit('SET_USER_CHANGES', {
                    userUuid: userUuid,
                    mealId: id
                })
            }
        },
        removeQuantity() {
            const id = this.meal.id
            const userUuid = this.$store.getters.getUserUuid

            this.$store.commit('SET_DAY_DATE', this.meal.date)

            if (this.meal.locked || this.checkDeadline(this.meal.deadlineOrder)) {
                return
            }

            if (this.quantity === this.orderedQuantity && this.checkDeadline(this.meal.deadlineCancel)) {
                this.removeQuantityButtonActive = false
                this.errorText = errors["CANCEL_DEADLINE_PASSED"]
                this.showError = true
                return
            }

            if (this.quantity >= 1) {
                this.quantity -= 1
                this.addQuantityButtonActive = true
                this.errorText = ''
                this.showError = false

                if (this.isDessertOrSideDish) {
                    this.$store.commit('SET_DESSERT_SIDEDISH_FILTER', false)
                }

                this.updateQuantity(this.quantity)

                if (this.quantity === 0) {
                    this.removeQuantityButtonActive = false
                }

                if (this.quantity === this.orderedQuantity) {
                    this.isChanged = false
                    this.$store.commit('REMOVE_USER_CHANGES', {
                        userUuid: userUuid,
                        mealId: id
                    })
                    return
                }
                this.isChanged = true
                this.$store.commit('SET_USER_CHANGES', {
                    userUuid: userUuid,
                    mealId: id
                })
            }
        },
        updateCartOrder(id, quantityChange) {
            let cartOrders = this.$store.getters.getOrders

            if (cartOrders.length === 0) {
                return
            }

            for (let day of cartOrders) {
                for (let orderedMeal of day.orderedMeals) {
                    if (orderedMeal.mealId === id) {
                        day.quantity = day.quantity - orderedMeal.quantity + quantityChange
                        orderedMeal.quantity = quantityChange

                        if (quantityChange === 0) {
                            day.quantity -= orderedMeal.quantity
                        }
                    }
                }
            }

            this.$store.commit('SET_ORDER_LIST', cartOrders)
        },
        updateQuantity(quantityChange) {
            let id = this.meal.id
            const weekOrderList = this.$store.getters.getWeekOrderList[this.$store.getters.getUserUuid][this.selectedWeekNumber]
            const menusWithError = this.$store.getters.getMenusWithError


            if (menusWithError.includes(this.meal.id)) {
                this.$store.commit('REMOVE_MENU_WITH_ERROR', this.meal.id)
                this.showError = false
                this.errorText = ''
                this.orderError = false
            }


            if (quantityChange < this.orderedQuantity && this.checkDeadline(this.meal.deadlineCancel)) {
                this.removeQuantityButtonActive = true
                this.errorText = errors["CANCEL_DEADLINE_PASSED"]
                this.showError = true
                quantityChange = this.orderedQuantity
                this.quantity = quantityChange
            }

            if (quantityChange < 0) {
                quantityChange = 0
                this.quantity = quantityChange
                this.errorText = 'Unzulässige Eingabe'
                this.showError = true
            }

            if (quantityChange > 99) {
                quantityChange = 99
                this.quantity = quantityChange
                this.errorText = errors["MAX_QUANTITY_EXCEEDS"]
                this.showError = true
            }

            if ((!this.meal.multipleOrders || this.isDessertOrSideDish) && quantityChange > 1) {
                this.addQuantityButtonActive = false
                this.removeQuantityButtonActive = true
                this.errorText = errors["MAX_QUANTITY_ONE"]
                this.showError = true
                quantityChange = 1
                this.quantity = quantityChange
            }

            for (let day of weekOrderList.orderDays) {
                for (let meal of day.orderedMeals) {
                    if (meal.mealId === id) {
                        day.quantity = day.quantity - meal.quantity + quantityChange
                        meal.quantity = quantityChange

                        if (quantityChange === 0) {
                            day.quantity -= meal.quantity
                        }
                        this.checkOrderStatus()
                    }
                }
            }
            this.updateCartOrder(id, quantityChange)
            this.$store.commit('SET_WEEK_ORDER_LIST', weekOrderList)
        },
        getSelectedMeal() {
            let id = this.meal.id
            const weekOrderList = this.$store.getters.getWeekOrderList[this.$store.getters.getUserUuid]
            for (let week in weekOrderList) {
                for (let orderDay of weekOrderList[week].orderDays) {
                    for (let meal of orderDay.orderedMeals) {
                        if (meal.mealId === id) {
                            this.$store.commit('SET_WEEK_NUMBER', week)
                            this.selectedWeekNumber = week
                            return meal
                        }
                    }
                }
            }
            return false
        },
        checkOrderError() {
            const menusWithError = this.$store.getters.getMenusWithError
            if (menusWithError === null || menusWithError.length === 0) {
                this.$store.commit('SET_ORDER_ERROR', false)
                this.$store.commit('REMOVE_ERROR_DETAILS')
                return
            }

            if (menusWithError.includes(this.meal.id)) {
                const errorDetails = this.$store.getters.getErrorDetails

                for (const error of errorDetails.errorCauses) {
                    if (error.mealId === this.meal.id) {
                        this.showError = true
                        this.errorText = errors[error.errorSummary]
                        this.orderError = true
                        return
                    }
                }
            }

            this.showError = false
            this.errorText = ''
            this.orderError = false
        },
        checkOrderStatus() {
            if (this.orderedQuantity > 0) {
                this.isOrdered = true
            }

            if (this.quantity === this.orderedQuantity) {
                this.isChanged = false
            }

            if (this.quantity === 0) {
                this.isChanged = false
                this.isOrdered = false
                this.removeQuantityButtonActive = false
                this.addQuantityButtonActive = true
            }

            if (this.quantity !== this.orderedQuantity) {
                this.isChanged = true
            }

            if (this.quantity === 99) {
                this.removeQuantityButtonActive = true
                this.addQuantityButtonActive = false
            }

            if (!this.meal.multipleOrders && this.quantity === 1) {
                this.addQuantityButtonActive = false
            }

            if (this.quantity === this.orderedQuantity && this.checkDeadline(this.meal.deadlineCancel)) {
                this.removeQuantityButtonActive = false
            }
        },
    },
    mounted() {
        this.selectedMeal = this.getSelectedMeal()
        this.quantity = this.selectedMeal.quantity
        this.orderedQuantity = this.selectedMeal.orderedQuantity
        this.checkOrderStatus()
        this.checkOrderError()
    },
    updated() {
        this.checkOrderError()
    }
}
</script>

<style lang="scss" scoped>
.error {
    color: $warning-color;
    bottom: -25px;
    width: 100%;
}

.button-container {
    border: 2px solid $primary;
    border-radius: 500px;
    padding: 10px 16px;
    width: 150px;

    &.ordered {
        border: 2px solid $green;
    }

    &.changed {
        border: 2px solid $yellow;
    }

    &.inactive {
        border: 2px solid $dark-grey;

        input {
            color: $dark-grey;

            &:focus {
                outline-style: none;
                box-shadow: none;
                border-color: transparent;
            }
        }

        img {
            opacity: .4;
        }
    }

    &.error {
        border: 2px solid $warning-color;
    }

    .quantity-button {
        cursor: pointer;

        &.inactive {
            opacity: .4;
        }
    }

    input {
        text-align: center;
        font-size: $fontsize-headline7;
        font-weight: 600;
        color: black;
        width: 3rem;
        border: none;

        &::placeholder {
            color: $primary;
        }
    }
}

.iPhone {
    .button-container {
        padding: 6px 16px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

</style>
