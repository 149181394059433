<template>
    <h1>Registrieren</h1>
    <p>
        Um unseren Online-Bestellservice nutzen zu können, füllen Sie bitte folgendes Formular aus.
    </p>
    <p class="subheadline">Wohin dürfen wir liefern?</p>
    <ul>
        <li @click="nextStep(1)">Auf Arbeit</li>
        <li @click="nextStep(2)">Nach Hause</li>
        <li @click="nextStep(3)">In die Schule</li>
        <li @click="nextStep(4)">In die Kita</li>
    </ul>
</template>

<script>
export default {
    name: 'RegisterDestination',
    methods: {
        nextStep(type) {
            sessionStorage.setItem('registrationType', type)
            this.$parent.addStep()
        }
    }
}
</script>

<style lang="scss" scoped>
ul {
    border-top: 1px solid $middle-light-grey;

    li {
        font-weight: $font-weight-bold;
        cursor: pointer;
        padding: .7rem 0;
        border-bottom: 1px solid $middle-light-grey;
        position: relative;

        &:after {
            border: 1px solid $primary;
            border-right: 0;
            border-top: 0;
            content: " ";
            display: block;
            height: 0.4em;
            margin-top: -1em;
            position: absolute;
            transform: rotate(225deg);
            width: 0.4em;
            cursor: pointer;
            right: 0;
        }
    }
}
</style>