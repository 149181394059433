import {createApp} from 'vue'
import {createStore} from 'vuex'
import App from './App.vue'
import router from './router'
import mutations from "./store/mutations";
import actions from "./store/actions";
import getters from "./store/getters";
import clickOutside from "./directives/clickOutside"
import VueCookies from 'vue3-cookies'
import VueGtag from "vue-gtag";

const state = {
    login: false,
    jwt: null,
    selectedDay: '',
    selectedWeek: {
        from: '',
        to: '',
    },
    userMenu: '',
    userList: null,
    userUuid: '',
    weekOrderList: {},
    orders: [],
    selectedMeal: null,
    modalVisible: false,
    desktopListVisible: false,
    filters: {
        activeFilters: [],
        isMenuActive: false,
        isSaladActive: false
    },
    orderError: false,
    errorDetails: null,
    errorMenus: [],
    sidebarActive: false,
    dessertOrSideDish: '',
    dayDate: ''
}

const store = createStore({
    state,
    actions,
    mutations,
    getters
})

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueCookies, {
    expireTimes: '1y'
})
app.directive('click-outside', clickOutside.clickOutside)
app.use(VueGtag, {
    appName: 'Gourmetta Web App',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: process.env.VUE_APP_GA_ID
    }
}, router)
app.mount('#app')

export default store

