<template>
    <div class="container is-fluid is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between">
        <IconSuccess />
        <p class="success-greet">Danke! Sie haben es fast geschafft!</p>
        <p class="success-sub-greet">In Kürze erhalten Sie eine E-Mail mit Ihren Anmeldedaten.</p>
        <Button text="Zur Anmeldung" @click="$router.push('/login')"/>
    </div>
</template>

<script>
import IconSuccess from '@/assets/images/icon_success_register.svg?inline'
import Button from "@/views/components/ui/Button"

export default {
    name: 'RegisterSuccess',
    components: {
        IconSuccess,
        Button
    },
    mounted() {
        this.$store.commit('REMOVE_FORM_REGISTRATION')
    }
}
</script>

<style lang="scss" scoped>
.container {
    min-height: 50vh;
    text-align: center;
    padding: 3rem;

    .success-greet {
        font-weight: $font-weight-bold;
        font-size: $font-size-large;
    }

    .success-sub-greet {
        font-weight: $font-weight-normal;
        font-size: $font-size-small;
    }
}
</style>
