import getters from './getters'
import {getISOWeek, parseISO} from "date-fns"

const toggleFilter = (activeFilters, filterToInsert, filterToRemove) => {
    if (activeFilters.includes(filterToRemove)) {
        activeFilters.push(filterToInsert)
        activeFilters = activeFilters.filter(item => item !== filterToRemove)
        return activeFilters
    }
}

const mutations = {
    SET_LOGIN(state) {
        localStorage.setItem('isLoggedIn', 'true')
        state.login = true
    },
    SET_JWT(state, jwt) {
        state.jwt = jwt
        localStorage.setItem('jwt', jwt)
        state.login = jwt
    },
    REMOVE_JWT(state) {
        localStorage.removeItem('jwt')
        state.jwt = null
    },
    REMOVE_LOGIN(state) {
        localStorage.clear()
        state.login = false
        state.weekOrderList = undefined
        this.commit('REMOVE_USER_LIST')
    },
    REMOVE_USER_LIST(state) {
        state.userList = null
    },
    SET_SELECTED_DAY(state, day) {
        state.day = day
    },
    SET_SELECTED_WEEK(state, week) {
        localStorage.setItem('selectedWeek', JSON.stringify(week))
        state.selectedWeek = week
    },
    SET_WEEK_NUMBER(state, weekNumber) {
        localStorage.setItem('weekNumber', weekNumber.toString())
        state.weekNumber = weekNumber
    },
    REMOVE_ORDER_ACTIVE_USER(state) {
        let userUuid = getters.getUserUuid()
        let weekOrderList = JSON.parse(localStorage.getItem('weekOrderList'))
        let userList = JSON.parse(localStorage.getItem('userList'))

        if (userList.uuid === userUuid) {
            userList.modifiedMeals = []
        }

        for (let user of userList.accounts) {
            if (user.uuid === userUuid) {
                user.modifiedMeals = []
            }
        }

        weekOrderList[userUuid] = {}

        localStorage.setItem('userList', JSON.stringify(userList))
        localStorage.setItem('weekOrderList', JSON.stringify(weekOrderList))
        state.userList = userList
        state.weekOrderList = weekOrderList
    },
    SET_WEEK_ORDER_LIST(state, weekOrder) {
        for (let day of weekOrder.orderDays) {
            if (day.orderedQuantity === undefined) {
                day.orderedQuantity = day.quantity

                for (let menu of day.orderedMeals) {
                    menu.orderedQuantity = menu.quantity
                }
            }
        }

        let userUuid = getters.getUserUuid()
        let weekNumber = getters.getWeekNumber()
        let weekOrderList = JSON.parse(localStorage.getItem('weekOrderList'))

        if (weekOrderList === null) {
            weekOrderList = {}
        }

        if (weekOrderList[userUuid] === undefined) {
            weekOrderList[userUuid] = {}
        }

        weekOrderList[userUuid][weekNumber] = weekOrder
        localStorage.setItem('weekOrderList', JSON.stringify(weekOrderList))
        state.weekOrderList = weekOrderList
    },
    UPDATE_WEEK_ORDER_LIST(state, weekOrder) {
        let userUuid = getters.getUserUuid()
        let weekOrderList = JSON.parse(localStorage.getItem('weekOrderList'))
        let newWeekOrder = {}

        for (let day of weekOrder.orderDays) {
            let kw = getISOWeek(parseISO(day.date))
            if (!weekOrderList[userUuid] || !weekOrderList[userUuid][kw]) {
                if (!newWeekOrder[kw]) {
                    newWeekOrder[kw] = {
                        'from': day.date,
                        'orderDays': [],
                        'to': day.date,
                        'userUuid': userUuid
                    }
                }

                if (day.orderedQuantity === undefined) {
                    day.orderedQuantity = day.quantity

                    for (let menu of day.orderedMeals) {
                        menu.orderedQuantity = menu.quantity
                    }
                }

                newWeekOrder[kw].orderDays.push(day)
                newWeekOrder[kw].to = day.date
            }
        }

        if (weekOrderList === null) {
            weekOrderList = {}
        }

        if (!weekOrderList[userUuid]) {
            weekOrderList[userUuid] = {}
        }

        for (let kw in newWeekOrder) {
            if (!weekOrderList[userUuid][kw] && newWeekOrder[kw]) {
                weekOrderList[userUuid][kw] = newWeekOrder[kw]
            }
        }

        localStorage.setItem('weekOrderList', JSON.stringify(weekOrderList))
        state.weekOrderList = weekOrderList
    },
    SET_ORDERED_LIST(state, orderedList) {
        const json = JSON.stringify(orderedList)
        localStorage.setItem('orderedList', json)
        state.orderedList = json
    },
    SET_USER_UUID(state, userUuid) {
        localStorage.setItem('userUuid', userUuid)
        state.userUuid = userUuid
    },
    SET_USER_LIST(state, userList) {
        userList.modifiedMeals = []
        for (let user of userList.accounts) {
            user.modifiedMeals = []
        }
        const json = JSON.stringify(userList)
        localStorage.setItem('userList', json)
        state.userList = userList
    },
    SET_SELECTED_MEAL(state, meal) {
        state.selectedMeal = meal
    },
    TOGGLE_MODAL(state, modalState) {
        state.modalVisible = modalState
    },
    TOGGLE_DESKTOP(state, desktopListVisible) {
        state.desktopListVisible = desktopListVisible
    },
    SET_ACTIVE_FILTER(state, filter) {
        let activeFilters = JSON.parse(localStorage.getItem('activeFilters'))

        if (filter === 'Alles') {
            localStorage.setItem('activeFilters', '[]')
            localStorage.setItem('isMenuActive', 'false')
            localStorage.setItem('isSaladActive', 'false')
            state.filters.activeFilters = []
            return
        }

        if (activeFilters === null) {
            state.filters.activeFilters.push(filter)
            localStorage.setItem('activeFilters', JSON.stringify(state.filters.activeFilters))
            return
        }

        if (activeFilters.includes(filter)) {
            activeFilters = activeFilters.filter(item => item !== filter)
            localStorage.setItem('activeFilters', JSON.stringify(activeFilters))
            state.filters.activeFilters = activeFilters
            return
        }

        if (filter === "Menüs" && activeFilters.includes("Salate")) {
            activeFilters = toggleFilter(activeFilters, 'Menüs', 'Salate')
            localStorage.setItem('activeFilters', JSON.stringify(activeFilters))
            state.filters.activeFilters = activeFilters
            return
        }

        if (filter === "Salate" && activeFilters.includes("Menüs")) {
            activeFilters = toggleFilter(activeFilters, 'Salate', 'Menüs')
            localStorage.setItem('activeFilters', JSON.stringify(activeFilters))
            state.filters.activeFilters = activeFilters
            return
        }

        activeFilters.push(filter)
        localStorage.setItem('activeFilters', JSON.stringify(activeFilters))
        state.filters.activeFilters = activeFilters
    },
    SET_MENU_FILTER(state, status) {
        const stringyStatus = status.toString()
        localStorage.setItem('isMenuActive', stringyStatus)
        state.filters.isMenuActive = status
    },
    SET_SALAD_FILTER(state, status) {
        const stringyStatus = status.toString()
        localStorage.setItem('isSaladActive', stringyStatus)
        state.filters.isSaladActive = status
    },
    SET_USER_CHANGES(state, payload) {
        let userList = JSON.parse(localStorage.getItem('userList'))
        if (userList.uuid === payload.userUuid) {
            if (userList.modifiedMeals.includes(payload.mealId)) {
                return
            }
            userList.modifiedMeals.push(payload.mealId)
            localStorage.setItem('userList', JSON.stringify(userList))
            state.userList = userList
            return
        }

        if (userList.accounts.length === 0) {
            return
        }

        for (let user of userList.accounts) {
            if (user.uuid === payload.userUuid) {
                if (user.modifiedMeals.includes(payload.mealId)) {
                    return
                }
                user.modifiedMeals.push(payload.mealId)
                localStorage.setItem('userList', JSON.stringify(userList))
                state.userList = userList
                return
            }
        }
    },
    REMOVE_USER_CHANGES(state, payload) {
        let userList = JSON.parse(localStorage.getItem('userList'))
        if (userList.uuid === payload.userUuid) {
            userList.modifiedMeals = userList.modifiedMeals.filter(item => item !== payload.mealId)
            localStorage.setItem('userList', JSON.stringify(userList))
            state.userList = userList
            return
        }

        if (userList.accounts.length === 0) {
            return
        }

        for (let user of userList.accounts) {
            if (user.uuid === payload.userUuid) {
                user.modifiedMeals = user.modifiedMeals.filter(item => item !== payload.mealId)
                localStorage.setItem('userList', JSON.stringify(userList))
                state.userList = userList
                return
            }
        }
    },
    SET_MODIFIED_MEALS(state, payload) {
        let userList = JSON.parse(localStorage.getItem('userList'))

        if (userList.uuid === payload.userUuid) {
            userList.modifiedMeals = payload.modifiedMeals
            localStorage.setItem('userList', JSON.stringify(userList))
            return
        }

        if (userList.accounts.length === 0) {
            return
        }

        for (let user of userList.accounts) {
            if (user.uuid === payload.userUuid) {
                user.modifiedMeals = payload.modifiedMeals
                localStorage.setItem('userList', JSON.stringify(userList))
                return
            }
        }
    },
    SET_ORDER_LIST(state, orders) {
        localStorage.setItem('orders', JSON.stringify(orders))
        state.orders = orders
    },
    SET_USER_WITH_PENDING_CHANGES(state, userWithPendingChanges) {
        state.userWithPendingChanges = userWithPendingChanges
    },
    SET_ORDER_ERROR(state, bool) {
        localStorage.setItem('orderError', bool.toString())
        state.orderError = bool
    },
    SET_ERROR_DETAILS(state, orderError) {
        localStorage.setItem('errorDetails', JSON.stringify(orderError))
        state.errorDetails = orderError
    },
    REMOVE_ERROR_DETAILS(state) {
        localStorage.removeItem('errorDetails')
        state.errorDetails = null
    },
    SET_MENU_WITH_ERROR(state, menuId) {
        let savedErrorMenus = JSON.parse(localStorage.getItem('errorMenus'))

        if (savedErrorMenus === null) {
            savedErrorMenus = []
            savedErrorMenus.push(menuId)
            localStorage.setItem('errorMenus', JSON.stringify(savedErrorMenus))
            state.errorMenus = savedErrorMenus
        }

        if (savedErrorMenus.includes(menuId)) {
            return
        }

        savedErrorMenus.push(menuId)
        localStorage.setItem('errorMenus', JSON.stringify(savedErrorMenus))
        state.errorMenus = savedErrorMenus
    },
    REMOVE_MENU_WITH_ERROR(state, menuId) {
        let savedErrorMenus = JSON.parse(localStorage.getItem('errorMenus'))
        if (!savedErrorMenus) {
            return
        }

        if (!savedErrorMenus.includes(menuId)) {
            return
        }

        savedErrorMenus = savedErrorMenus.filter(menu => menu !== menuId)
        localStorage.setItem('errorMenus', JSON.stringify(savedErrorMenus))
        state.errorMenus = savedErrorMenus
    },
    SET_STEP(state, step) {
        sessionStorage.setItem('actualStep',step)
        state.actualStep = step
    },
    ADD_STEP(state) {
        let actualSavedStep = sessionStorage.getItem('actualStep')
        if (actualSavedStep) {
            let actualStep = parseInt(actualSavedStep) + 1
            sessionStorage.setItem('actualStep', actualStep.toString())
            state.actualStep = actualStep
            return
        }
        sessionStorage.setItem('actualStep', (state.actualStep + 1).toString())
        state.actualStep += 1
    },
    REDUCE_STEP(state) {
        let actualSavedStep = sessionStorage.getItem('actualStep')
        if (actualSavedStep) {
            let actualStep = parseInt(actualSavedStep) - 1
            sessionStorage.setItem('actualStep', actualStep.toString())
            state.actualStep = actualStep
            return
        }
        sessionStorage.setItem('actualStep', (state.actualStep - 1).toString())
        state.actualStep -= 1
    },
    REMOVE_STEP(state) {
        let actualSavedStep = sessionStorage.getItem('actualStep')
        if (actualSavedStep) {
            sessionStorage.setItem('actualStep', '1')
            state.actualStep = 1
            return
        }
        sessionStorage.setItem('actualStep', '1')
        state.actualStep = 1
    },
    SET_FORM_DATA(state, form) {
        let formName = 'form'+sessionStorage.getItem('registrationType')
        sessionStorage.setItem(formName, JSON.stringify(form))
    },
    REMOVE_FORM_REGISTRATION(state) {
        sessionStorage.clear()
        sessionStorage.setItem('actualStep', '1')
        state.actualStep = 1
    },
    TOGGLE_SIDEBAR(state) {
        state.sidebarActive = !state.sidebarActive
        if (state.sidebarActive) {
            document.querySelector('html').style.overflowY = 'hidden'
            return
        }
        document.querySelector('html').style.overflowY = 'auto'
    },
    TOGGLE_COOKIE_LAYER(state, cookieLayerState) {
        state.cookieLayerState = cookieLayerState
    },
    SET_DAY_DATE(state, dayDate) {
        localStorage.setItem('dayDate', dayDate)
        state.dayDate = dayDate
    },
    SET_DESSERT_SIDEDISH_FILTER(state, dessertOrSideDish) {
        let savedWeekOrderList = getters.getWeekOrderList()
        let selectedWeek = getters.getWeekNumber()
        let dayDate = getters.getDayDate()
        let activeUser = getters.getUserUuid()

        for (let day of savedWeekOrderList[activeUser][selectedWeek].orderDays) {
            if (day.date === dayDate) {
                day.dessertOrSideDish = dessertOrSideDish
                localStorage.setItem('weekOrderList', JSON.stringify(savedWeekOrderList))
                state.weekOrderList = savedWeekOrderList
            }
        }
    },
    SET_MONTH_ORDER_OVERVIEW_LIST(state, monthOrder) {
        let userUuid = getters.getUserUuid()
        let monthOrderOverviewList = JSON.parse(localStorage.getItem('monthOrderOverviewList'))

        if (monthOrderOverviewList === null) {
            monthOrderOverviewList = {}
        }

        if (monthOrderOverviewList[userUuid] === undefined) {
            monthOrderOverviewList[userUuid] = {}
        }

        for (let day of monthOrder.orderDays) {
            if (day.quantity > 0) {
                monthOrderOverviewList[userUuid][day.date] = day
            }
        }

        localStorage.setItem('monthOrderOverviewList', JSON.stringify(monthOrderOverviewList))
        state.monthOrderOverviewList = monthOrderOverviewList
    },
    REMOVE_MONTH_ORDER_OVERVIEW_LIST(state) {
        let userUuid = getters.getUserUuid()
        let monthOrderOverviewList = JSON.parse(localStorage.getItem('monthOrderOverviewList'))

        if (monthOrderOverviewList !== undefined &&
            monthOrderOverviewList !== null &&
            monthOrderOverviewList[userUuid] !== undefined)
        {
            delete monthOrderOverviewList[userUuid]

            localStorage.setItem('monthOrderOverviewList', JSON.stringify(monthOrderOverviewList))
            state.monthOrderOverviewList = monthOrderOverviewList
        }
    },
    SET_RANGE(state, range) {
        localStorage.setItem('range', JSON.stringify(range))
    },
    SET_ACTIVE_WEEK(state, activeWeek) {
        localStorage.setItem('activeWeek', activeWeek)
    },
    REMOVE_ACTIVE_WEEK() {
        localStorage.removeItem('activeWeek')
    }
}

export default mutations