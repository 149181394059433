<template>
    <span class="price">{{ formattedPrice }}<span v-show="withPiece">/Stück</span></span>
</template>

<script>
export default {
    name: 'Price',
    props: [
        'price',
        'withPiece'
    ],
    data() {
        return {
            formattedPrice: ''
        }
    },
    methods: {
        formatPrice(price) {
            return price.toFixed(2).toString().replace('.',',') + ' €'
        }
    },
    mounted() {
        this.formattedPrice = this.formatPrice(this.price)
    }
}
</script>

<style lang="scss" scoped>
.price {
    font-weight: $font-weight-normal;
    font-size: $font-size-large;
}
</style>
