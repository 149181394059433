<template>
    <div v-for="meal of meals" :key="meal.id">
        <OrderOverviewListItem :meal="meal" />
    </div>
</template>

<script>
import OrderOverviewListItem from "@/views/components/ui/OrderOverviewListItem"

export default {
    name: 'OrderOverviewList',
    props: [
        'days'
    ],
    components: {
        OrderOverviewListItem
    },
    computed: {
        meals() {
            return this.days?.orderedMeals?.filter(meal => meal.quantity > 0) || []
        }
    }
}
</script>