import {createWebHistory, createRouter} from "vue-router";

import Login from "@/views/pages/Login.vue";
import NotFound from "@/views/pages/NotFound.vue";
import Logout from "@/views/pages/Logout";
import MenuPlan from "@/views/pages/MenuPlan";
import Cart from "@/views/pages/Cart";
import Register from "@/views/pages/Register";
import UserProfile from "@/views/pages/UserProfile";
import ChangePassword from "@/views/pages/ChangePassword";
import OrderOverview from "@/views/pages/OrderOverview";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Login,
        meta: {
            requiresLogin: true
        }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            requiresLogin: true
        }
    },
    {
        path: "/logout",
        name: "Logout",
        component: Logout,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/speiseplan",
        name: "MenuPlan",
        component: MenuPlan,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/warenkorb",
        name: "Cart",
        component: Cart,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/registrierung",
        name: "Register",
        component: Register,
        meta: {
            requiresLogin: true
        }
    },
    {
        path: "/kundenkonto",
        name: "UserProfile",
        component: UserProfile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/bestelluebersicht",
        name: "OrderOverview",
        component: OrderOverview,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/passwort-aendern",
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/:catchAll(.*)",
        name: 'NotFound',
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 }
    }
});

router.beforeEach((to, from, next) => {
    let isLoggedIn = localStorage.getItem('isLoggedIn') !== null
    let jwtToken = localStorage.getItem('jwt')
    let validJwt = false

    if (jwtToken !== null) {
        let token = JSON.parse(atob(jwtToken.split('.')[1]))

        if (token.exp*1000 >= Date.now()) {
            validJwt = true
        }
    }

    if((!isLoggedIn || !validJwt) && to.matched.some(record => record.meta.requiresAuth)) {
        next({name: 'Login'})
        return
    }

    if(isLoggedIn && validJwt && to.matched.some(record => record.meta.requiresLogin)) {
        next({name: 'MenuPlan'})
        return
    }

    next()
})

export default router;
