<template>
    <div v-if="!isLoading" class="cart-footer-summary is-flex is-flex-direction-column is-justify-content-space-between">
        <span class="changed-price">Gesamtbetrag: {{ formatPrice(changedPrice) }} €</span>
        <Button class="button mt-4" :class="{'inactive':buttonInactive}" text="Bestellung senden" @click="putOrder"/>
    </div>
    <Modal v-show="modalVisible">
        <div class="modal-message">
            <p>{{ modalText }}</p>
            <Button class="button mt-4" :class="{'is-success':orderSuccess, 'is-danger':!orderSuccess}" aria-label="close" @click="hideModal()" text="Ok"/>
        </div>
    </Modal>
</template>
<script>
import Button from "@/views/components/ui/Button"
import Modal from "@/views/components/ui/Modal"
import errors from "@/utils/errors.json"

export default {
    name: 'CartFooterSummary',
    components: {
        Button,
        Modal
    },
    props: [
        'isLoading'
    ],
    data() {
        return {
            changedPrice: 0,
            orders: [],
            buttonInactive: false,
            modalVisible: false,
            modalText: '',
            usersWithPendingChanges: [],
            orderSuccess: false
        }
    },
    methods: {
        async putOrder() {
            if (this.buttonInactive) {
                return
            }

            this.$parent.isLoading = true
            this.$store.commit('SET_ORDER_ERROR', false)
            this.orderSuccess = true
            const response = await this.$store.dispatch('putOrder', this.orders)

            if (response.errorSummary && response.errorSummary !== 'NO_ORDER_EMAIL') {
                await this.$parent.getCart()
                this.orderSuccess = false
                this.modalText = errors[response.errorSummary]
                this.$store.commit('TOGGLE_MODAL', true)
                this.$parent.isLoading = false

                if (response.errorCauses.length > 0) {
                    this.$store.commit('SET_ORDER_ERROR', true)
                    this.$store.commit('SET_ERROR_DETAILS', response)
                }
                return
            }

            await this.$store.commit('REMOVE_ORDER_ACTIVE_USER')
            await this.$store.commit('REMOVE_MONTH_ORDER_OVERVIEW_LIST')
            this.usersWithPendingChanges = this.$store.getters.getUserWithPendingChanges
            await this.$parent.getCart()

            this.modalText = 'Alle Bestellungen wurden erfolgreich erfasst.'

            if (this.usersWithPendingChanges.length > 0) {
                this.modalText = 'Die Bestellung wurde erfolgreich erfasst. Bei ' + this.usersWithPendingChanges.length + ' Konten sind noch Bestellungen offen.'
            }
            this.orderSuccess = true
            this.$store.commit('TOGGLE_MODAL', true)

            this.$parent.isLoading = false
        },
        hideModal() {
            this.$store.commit('TOGGLE_MODAL', false)
        },
        formatPrice(price) {
            return price.toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        },
        calcPrices() {
            this.changedPrice = 0
            this.orders = this.$store.getters.getOrders

            for(let day of this.orders) {
                for (let orderedMeal of day.orderedMeals) {
                    let price = orderedMeal.meal.price

                    if (orderedMeal.orderedQuantity !== orderedMeal.quantity) {
                        let changedQuantity = orderedMeal.quantity - orderedMeal.orderedQuantity
                        let totalChangedPrice = price * changedQuantity
                        this.changedPrice += totalChangedPrice
                    }
                }
            }
        },
        checkModifiedMealsActiveUser() {
            let userUuid = this.$store.getters.getUserUuid
            let userList = this.$store.getters.getUserList

            if (userList.uuid === userUuid) {
                return !(userList.modifiedMeals.length > 0)
            }

            if (userList.accounts.length === 0) {
                return true
            }

            for (let user of userList.accounts) {
                if (user.uuid === userUuid) {
                    return !(user.modifiedMeals.length > 0)
                }
            }

            return true
        }
    },
    beforeMount() {
        this.orders = this.$store.getters.getOrders
        this.buttonInactive = this.checkModifiedMealsActiveUser()
        this.buttonInactive = this.$store.getters.getOrderErrorStatus
        this.calcPrices()
    },
    watch: {
        "$store.state.modalVisible"(bool) {
            this.modalVisible = bool;
        },
        "$store.state.orders"() {
            this.calcPrices()
            this.buttonInactive = this.checkModifiedMealsActiveUser()
        }
    }
}
</script>

<style lang="scss" scoped>
.cart-footer-summary {
    padding: 1.5rem;
    margin: 0 20px 20px 20px;
    border-radius: $border-radius;
    background-color: $grey;

    .changed-price {
        align-self: flex-end;
    }

    .changed-price {
        font-weight: $font-weight-black;
        font-size: $font-size-xlarge;
    }

    .button {
        align-self: center;

        &.inactive {
            opacity: .15;
        }
    }
}

.modal-message {
    text-align: center;
}
</style>
