<template>
    <div class="container is-fluid not-found">
        <h1 class="title is-3">Seite nicht gefunden!</h1>
        <p>Oops, die angeforderte Seite wurde auf diesem Server nicht gefunden.</p>
        <router-link to="/">Zur Startseite</router-link>
    </div>
</template>

<style lang="scss" scoped>
    .not-found {
        height: 80vh;

        h1 {
            padding-top: 3rem;
        }
        p {
            margin-bottom: 1rem;
        }
        a {
            font-size: $font-size-small;
            font-weight: $font-weight-bold;
        }
    }
</style>