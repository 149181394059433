import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'X-Requested-With, Content-Type, Authorization, Accept, Cookie';
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('jwt')
    config.headers.Authorization = 'Bearer ' + token;

    return config;
});

const actions = {
    loginAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(
                'login',
                payload
            ).then((response) => {
                localStorage.clear()
                this.commit('REMOVE_USER_LIST')
                this.commit('SET_LOGIN')
                this.commit('SET_JWT', response.data.token)
                const parseJwt = (response) => {
                    try {
                        return JSON.parse(atob(response.data.token.split('.')[1]));
                    } catch (e) {
                        return null;
                    }
                };
                this.commit('SET_USER_UUID', parseJwt(response).userUuid)
                resolve(true)
            })
                .catch(() => {
                    reject('wrong credentials.')
                })
        })
    },
    getWeekOrder(context, params) {
        const userUuid = this.getters.getUserUuid
        return new Promise((resolve, reject) => {
            axios.get(
                'users/' + userUuid + '/order',
                {
                    params: params
                }
            ).then((response) => {
                resolve(response.data)
            }).catch(() => {
                reject('Session expired.')
                this.commit('REMOVE_LOGIN')
                window.location.reload()
            })
        })
    },
    getUserList(context, userUuid) {
        return new Promise((resolve, reject) => {
            axios.get(
                'users/' + userUuid
            ).then((response) => {
                this.commit('SET_USER_LIST', response.data)
                resolve(response.data)
            })
                .catch(() => {
                    reject('Session expired.')
                    this.commit('REMOVE_LOGIN')
                    window.location.reload()
                })
        })
    },
    putOrder(context, order) {
        const userUuid = this.getters.getUserUuid
        return axios.put(
            'users/' + userUuid + '/order',
            {
                orderDays: order
            }
        ).then((response) => {
            return response.data
        }).catch((error) => {
            if (error.response.status === 403) {
                this.commit('REMOVE_LOGIN')
                window.location.reload()
                return
            }

            return error.response.data
        })
    },
    postRegistration(context, form) {
        return new Promise((resolve, reject) => {
            axios.post(
                'registration',
                form
            ).then((response) => {
                resolve(response.data)
            }).catch(() => {
                reject('Session expired.')
            })
        })
    },
    changePassword(context, payload) {
        let userUuid = this.getters.getUserUuid
        return new Promise((resolve, reject) => {
            axios.post(
                'users/' + userUuid + '/password',
                payload
            ).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    },
    logout() {
        this.commit('REMOVE_LOGIN')
        this.commit('REMOVE_JWT')
    }
}

export default actions
