<template>
    <p v-if="!noChanges" class="menu-quantity is-flex is-justify-content-space-between">
        Änderung:&nbsp;<span v-if="mealQuantity > 0">+</span>{{ mealQuantity }}
    </p>
</template>

<script>
export default {
    name: 'MenuQuantity',
    data() {
        return {
            mealQuantity: 0,
            noChanges: true
        }
    },
    props: [
        'id'
    ],
    methods: {
        checkQuantity() {
            let userUuid = this.$store.getters.getUserUuid
            let menuList = this.$store.getters.getWeekOrderList[userUuid]

            for (let kw in menuList) {
                for (let day of menuList[kw].orderDays) {
                    for (let menu of day.orderedMeals) {
                        if (menu.mealId === this.id) {
                            if (menu.quantity === menu.orderedQuantity) {
                                this.noChanges = true
                                return
                            }
                            this.noChanges = false
                            this.mealQuantity = menu.quantity - menu.orderedQuantity
                            return
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.checkQuantity()
    },
    watch: {
        "$store.state.weekOrderList"() {
            this.checkQuantity()
        }
    }
}
</script>