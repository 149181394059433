<template>
    <div class="is-flex is-justify-content-space-between is-align-items-center is-flex-direction-column pt-2">
        <img class="login-logo" src="@/assets/images/logo_temp.svg" alt="">
        <div v-if="loginError" class="login-error mb-4">Login nicht erfolgreich.</div>
        <div class="field">
            <div class="control">
                <input class="input is-medium is-rounded"
                       type="text"
                       placeholder="Kundennummer"
                       aria-label="Benutzername"
                       v-model="username"
                       @keyup.enter="login"
                >
            </div>
        </div>

        <div class="field mb-0">
            <div class="control">
                <input class="input is-medium is-rounded"
                       type="password"
                       placeholder="Passwort"
                       aria-label="Passwort"
                       v-model="password"
                       @keyup.enter="login"
                >
            </div>
        </div>

        <a class="forgot-password my-4 is-size-7" href="#" @click="openForgotPassword">Passwort vergessen?</a>

        <Button text="Anmelden" @click="login"/>

        <router-link to="/registrierung" class="register-link mt-4 mb-6 is-size-7">
            Noch kein Konto? <span>Hier registrieren.</span>
        </router-link>
    </div>
    <Modal class="forgot-password-modal" v-if="isModalVisible">
        <p>Zur Zurücksetzung Ihres Passwortes wenden Sie sich bitte per E-Mail an
            bestellung<span style="display:none">null</span>@gourmetta.de
        </p>
        <Button text="Ok" @click="hideModal"/>
    </Modal>
</template>

<script>
import Button from '@/views/components/ui/Button.vue'
import Modal from "@/views/components/ui/Modal"

export default {
    name: 'Login',
    data() {
        return {
            username: this.$route.query.user || '', // Prefill username with query parameter value if set
            password: '',
            loginError: false,
            isLoggedIn: this.$store.getters.isLoggedIn,
            isModalVisible: false
        }
    },
    components: {
        Button,
        Modal
    },
    methods: {
        async login() {
            let payload = {
                "login": this.username,
                "password": this.password
            }
            this.$store.dispatch('loginAction', payload)
                .then(() => {
                    this.$router.push('/speiseplan')
                })
                .catch(() => {
                    this.loginError = true
                })
        },
        hideModal() {
            this.isModalVisible = false
        },
        openForgotPassword() {
            this.isModalVisible = true
        }
    }
}
</script>

<style lang="scss" scoped>
.login-logo {
    max-width: 30%;
    margin-bottom: 2rem;
    margin-top: 5rem;
}

.input {
    &.is-medium {
        font-size: $font-size-medium;
    }
}
.forgot-password-modal {
    text-align: center;
}

.title {
    font-weight: $font-weight-bold;
}

.forgot-password,
.login-error {
    color: $warning-color;
}

.register-link {
    span {
        font-weight: $font-weight-bold;
    }
}

@media all and (min-width: $tablet) {
    div.field,
    img,
    button {
        width: 255px;
    }
}
</style>
