<template>
    <div v-if="cookieLayerState" class="cookie-layer">
        <div v-if="cookiePreview" class="preview has-text-centered">
            <p class="small">
                Wenn Sie auf „Alle Cookies akzeptieren“ klicken, stimmen Sie der Speicherung von Cookies auf Ihrem Gerät
                zu, um die Websitenavigation zu verbessern, die Websitenutzung zu analysieren und unsere
                Marketingbemühungen zu unterstützen.
            </p>
            <div @click="removeCookiePreview" class="cookie-preview">Cookie-Einstellungen</div>
            <Button text="Alle Cookies akzeptieren" @click="allCookies"/>
        </div>
        <div v-else>
            <img src="@/assets/images/logo_temp.svg" alt="gourmetta logo" class="logo">
            <div class="has-text-centered">
                <p class="small">
                    Wenn Sie eine Website besuchen, kann diese Informationen über Ihren Browser abrufen oder speichern.
                    Dies geschieht meist in Form von Cookies. Hierbei kann es sich um Informationen über Sie, Ihre
                    Einstellungen oder Ihr Gerät handeln. Meist werden die Informationen verwendet, um die
                    erwartungsgemäße Funktion der Website zu gewährleisten. Durch diese Informationen werden Sie
                    normalerweise nicht direkt identifiziert. Dadurch kann Ihnen aber ein personalisierteres
                    Web-Erlebnis geboten werden. Da wir Ihr Recht auf Datenschutz respektieren, können Sie sich
                    entscheiden, bestimmte Arten von Cookies nicht zulassen. Klicken Sie auf die verschiedenen
                    Kategorieüberschriften, um mehr zu erfahren und unsere Standardeinstellungen zu ändern. Die
                    Blockierung bestimmter Arten von Cookies kann jedoch zu einer beeinträchtigten Erfahrung mit der
                    von uns zur Verfügung gestellten Website und Dienste führen.
                </p>
                <Button text="Alle zulassen" @click="allCookies"/>
            </div>
            <p class="headline">Einwilligungspräferenzen verwalten</p>
            <vue-collapsible-panel-group>
                <vue-collapsible-panel :expanded="false">
                    <template #title>
                        <div class="top">
                            <div>Unbedingt erforderliche Cookies</div>
                            <div class="check always">Immer aktiv</div>
                        </div>
                    </template>
                    <template #content>
                        <p class="small">
                            Diese Cookies sind zur Funktion der Website erforderlich und können in Ihren Systemen nicht
                            deaktiviert werden. In der Regel werden diese Cookies nur als Reaktion auf von Ihnen
                            getätigte Aktionen gesetzt, die einer Dienstanforderung entsprechen, wie etwa dem Festlegen
                            Ihrer Datenschutzeinstellungen, dem Anmelden oder dem Ausfüllen von Formularen. Sie können
                            Ihren Browser so einstellen, dass diese Cookies blockiert oder Sie über diese Cookies
                            benachrichtigt werden. Einige Bereiche der Website funktionieren dann aber nicht. Diese
                            Cookies speichern keine personenbezogenen Daten.
                        </p>
                    </template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                    <template #title>
                        <div class="label">Leistungs-Cookies</div>
                        <div class="field">
                            <input id="performance" type="checkbox" name="performance" v-model="cookiePreferences.performance"
                                   class="switch is-rounded is-success is-rtl" checked="checked">
                            <label for="performance" @click="switchCookie">&nbsp;</label>
                        </div>
                    </template>
                    <template #content>
                        <p class="small">
                            Diese Cookies ermöglichen es uns, Besuche und Verkehrsquellen zu zählen, damit wir die
                            Leistung unserer Website messen und verbessern können. Sie unterstützen uns bei der
                            Beantwortung der Fragen, welche Seiten am beliebtesten sind, welche am wenigsten genutzt
                            werden und wie sich Besucher auf der Website bewegen. Alle von diesen Cookies erfassten
                            Informationen werden aggregiert und sind deshalb anonym. Wenn Sie diese Cookies nicht
                            zulassen, können wir nicht wissen, wann Sie unsere Website besucht haben.
                        </p>
                    </template>
                </vue-collapsible-panel>
            </vue-collapsible-panel-group>
            <div class="has-text-centered">
                <Button class="small" text="Meine Auswahl bestätigen" @click="selectedCookies"/>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/ui/Button";
import {VueCollapsiblePanelGroup, VueCollapsiblePanel} from "@dafcoe/vue-collapsible-panel";

export default {
    name: 'CookieLayer',
    components: {
        Button,
        VueCollapsiblePanel,
        VueCollapsiblePanelGroup
    },
    data() {
        return {
            cookiePreview: true,
            cookieLayerState: true,
            cookiePreferences: {
                performance: true
            }
        }
    },
    beforeMount() {
        this.checkCookies()
    },
    methods: {
        switchCookie(e) {
            this.cookiePreferences.performance = !this.cookiePreferences.performance
            e.preventDefault()
            e.stopPropagation()
        },
        checkCookies() {
            this.$gtag.optOut()
            window['ga-disable-' + process.env.VUE_APP_GA_ID] = true
            if (!this.$cookies.isKey('cookiePreferences')) {
                this.$store.commit('TOGGLE_COOKIE_LAYER', true)
                return
            }

            if (!Object.keys(this.$cookies.get('cookiePreferences')).includes('performance')) {
                this.$store.commit('TOGGLE_COOKIE_LAYER', true)
                return
            }

            this.cookiePreferences = this.$cookies.get('cookiePreferences')
            this.$store.commit('TOGGLE_COOKIE_LAYER', false)
        },
        allCookies() {
            this.$gtag.optIn()
            window['ga-disable-' + process.env.VUE_APP_GA_ID] = false
            this.cookiePreferences.performance = true
            this.$cookies.set('cookiePreferences', { performance: true })
            this.$store.commit('TOGGLE_COOKIE_LAYER', false)
        },
        selectedCookies() {
            this.$gtag.optIn()
            window['ga-disable-' + process.env.VUE_APP_GA_ID] = false

            if (this.cookiePreferences.performance !== true) {
                this.$gtag.optOut()
                window['ga-disable-' + process.env.VUE_APP_GA_ID] = true
            }
            this.$cookies.set('cookiePreferences', this.cookiePreferences)
            this.$store.commit('TOGGLE_COOKIE_LAYER', false)
        },
        removeCookiePreview() {
            this.cookiePreview = false
        }
    },
    watch: {
        "$store.state.cookieLayerState"(cookieLayerState) {
            this.cookieLayerState = cookieLayerState
        }
    }
}
</script>

<style lang="scss">
.cookie-layer {
    max-height: 100%;
    overflow: auto;
    z-index: 99;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: $gap;
    background-color: $background-color-light;
    border-radius: $border-radius $border-radius 0 0;
    box-shadow: 0 0 $shadow-blur $shadow-color;

    .cookie-preview {
        color: $green;
        font-weight: $font-weight-semi-bold;
        padding-bottom: 1rem;

        &:hover {
            cursor: pointer;
        }
    }

    img.logo {
        max-height: 3em;
    }

    .headline {
        padding: 1rem 0;
        font-size: $font-size-large;
        font-weight: $font-weight-semi-bold;
    }

    p.small {
        font-size: $font-size-xsmall;
        padding-bottom: 1rem;
    }

    button.small {
        height: 2.5rem;
    }

    .top {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .check.always {
            color: $green;
            font-weight: $font-weight-semi-bold;
        }
    }

    .vcpg {
        border: 1px solid #969696;
        margin-bottom: 1rem;

        .vcp {
            border-bottom: 1px solid #969696;

            &:last-child {
                border: none;
            }

            &__header {
                flex-direction: row-reverse;
                font-weight: $font-weight-semi-bold;

                div.label {
                    width: 100%;
                }
                div.field {
                    width: auto;
                }
            }

            &__body {
                background-color: $very-light-grey;
            }

            .vcp__header-icon {
                margin-right: 1rem;
                transform: rotate(270deg);
            }

            &.vcp--expanded .vcp__header-icon {
                transform: rotate(360deg);
            }
        }
    }
}
</style>