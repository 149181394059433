<template>
    <div class="total-price-container">
        <p>Summe: {{ totalPrice }} €</p>
    </div>
</template>

<script>
export default {
    name: 'TotalPrice',
    data() {
        return {
            totalPrice: 0.0
        }
    },
    props: [
        'price',
        'id'
    ],
    methods: {
        calcTotalPrice() {
            let userUuid = this.$store.getters.getUserUuid
            let menuList = this.$store.getters.getWeekOrderList[userUuid]

            for (let kw in menuList) {
                for (let day of menuList[kw].orderDays) {
                    for (let menu of day.orderedMeals) {
                        if (menu.mealId === this.id) {
                            this.totalPrice = (this.price * menu.quantity).toFixed(2).replace('.',',')
                            return
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.calcTotalPrice()
    },
    watch: {
        "$store.state.weekOrderList"() {
            this.calcTotalPrice()
        }
    }
}
</script>

<style lang="scss" scoped>
.total-price-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    p {
        font-weight: $font-weight-bold;
        font-size: $fontsize-headline7;
    }
}
</style>