<template>
    <div class="container is-fluid cart-summary-container" v-if="!emptyCart">
        <div class="grey-container cart-summary-container__dates">
            {{ dateStart }}
            <span v-if="dateStart !== ''">-</span>
            {{ dateEnd }}
            <div class="day-amount-container">
                <span class="order-number" v-if="totalChanges !== 0">
                    <span class="badge changed-badge" v-show="totalChanges"/><span>{{ totalChanges }}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {format, parseISO} from 'date-fns'

export default {
    name: 'CartHeaderSummary',
    props: [
        'firstDate',
        'lastDate'
    ],
    data() {
        return {
            dateStart: null,
            dateEnd: null,
            totalChanges: '',
            totalOrders: '',
            emptyCart: true
        }
    },
    methods: {
        formatString(value, singular, plural) {
            if (value === 1 || value === -1) {
                return value + ' ' + singular
            }

            return value + ' ' + plural
        },
        formatDates() {
            if (this.firstDate === this.lastDate) {
                this.dateStart = ''
                this.dateEnd = format(parseISO(this.lastDate), 'dd.MM.yyyy')
                return
            }

            this.dateStart = format(parseISO(this.firstDate), 'dd.MM.')
            this.dateEnd = format(parseISO(this.lastDate), 'dd.MM.yyyy')
        },
        checkDates() {
            this.emptyCart = this.firstDate === null
            if (!this.emptyCart) {
                this.formatDates()

                let userList = this.$store.getters.getUserList
                let userUuid = this.$store.getters.getUserUuid

                if (userList.uuid !== userUuid) {
                    userList = userList.accounts.find(x => x.uuid === userUuid)
                }

                this.totalChanges = this.formatString(userList.modifiedMeals.length, 'Änderung', 'Änderungen')
                this.totalOrders = this.formatString(this.orders, 'Bestellung', 'Bestellungen')
            }
        }
    },
    beforeMount() {
        this.checkDates()
    },
    watch: {
        firstDate() {
            this.checkDates()
        },
        "$store.state.weekOrderList"() {
            this.checkDates()
        }
    }
}
</script>

<style lang="scss" scoped>
.cart-summary-container {
    text-align: center;
    position: relative;
    padding: 0;

    &__dates {
        font-weight: $font-weight-black;
        font-size: $font-size-large;
        display: block;
        padding: 1rem 0;
        border-radius: $border-radius $border-radius 0 0;
    }

    .day-amount-container {

        .order-number {
            font-size: $font-size-xsmall;
            font-weight: $font-weight-normal;
            color: $dark-grey;
            width: 100%;
        }

        .badge {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: .5em;
            border-radius: 50%;
        }

        .changed-badge {
            background-color: $yellow;
        }
    }
}
</style>