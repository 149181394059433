<template>
  <button class="button is-black is-medium is-rounded">
    <span class="has-text-weight-bold is-size-6">{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String
  }
}
</script>

<style lang="scss" scoped>
button {
  width: 254px;
}
</style>