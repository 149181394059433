<template>
    <div class="modal is-active is-clipped">
        <div class="modal-background" @click="$parent.hideModal()" />
        <div class="modal-content">
            <button class="modal-close is-large" aria-label="close" @click="$parent.hideModal()"></button>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: [
        'modalContent'
    ]
}
</script>

<style lang="scss" scoped>
.modal-content {
    background-color: $secondary;
    max-height: 80%;
    max-width: 480px; //600px * 80%
    width: 80%;
    margin: 0 auto;
    border-radius: 15px;
    padding: 3em 1.5rem;

    .modal-close {
        position: absolute;

        &:before,
        &:after {
            background-color: $primary;
        }
    }
}
</style>