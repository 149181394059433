<template>
    <h1>Registrieren</h1>
    <p>
        Geben Sie Ihre persönlichen Daten und Ihr Passwort ein.
    </p>
    <form v-on:submit.prevent="submitForm">
        <div v-if="registrationType==1">
            <p class="subheadline">Essen bereits Kollegen mit?</p>
            <div class="control">
                <label for="form1-alreadyCollegues-yes" class="radio">
                    <input type="radio" id="form1-alreadyCollegues-yes" value="true" v-model="form1.alreadyCollegues" />
                    Ja
                </label>
                <label for="form1-alreadyCollegues-no" class="radio">
                    <input type="radio" id="form1-alreadyCollegues-no" value="false" v-model="form1.alreadyCollegues" />
                    Nein
                </label>
            </div>
            <p class="subheadline">Lieferadresse</p>
            <div>
                <label for="form1-deliveryAddress-company" class="small">Firmenname*</label>
                <input id="form1-deliveryAddress-company" class="input" type="text" v-model="form1.deliveryAddress.company" placeholder="Firmenname" required>
            </div>
            <div>
                <label for="form1-deliveryAddress-firstName" class="small">Vorname*</label>
                <input id="form1-deliveryAddress-firstName" class="input" type="text" v-model="form1.deliveryAddress.firstName" placeholder="Vorname" required>
            </div>
            <div>
                <label for="form1-deliveryAddress-lastName" class="small">Nachname*</label>
                <input id="form1-deliveryAddress-lastName" class="input" type="text" v-model="form1.deliveryAddress.lastName" placeholder="Nachname" required>
            </div>
            <div>
                <label for="form1-deliveryAddress-address" class="small">Straße und Hausnummer*</label>
                <input id="form1-deliveryAddress-address" class="input" type="text" v-model="form1.deliveryAddress.address" placeholder="Straße und Hausnummer" required>
            </div>
            <div>
                <label for="form1-deliveryAddress-zipCode" class="small">Postleitzahl*</label>
                <input id="form1-deliveryAddress-zipCode" class="input" type="text" v-model="form1.deliveryAddress.zipCode" placeholder="Postleitzahl" required>
            </div>
            <div>
                <label for="form1-deliveryAddress-city" class="small">Ort*</label>
                <input id="form1-deliveryAddress-city" class="input" type="text" v-model="form1.deliveryAddress.city" placeholder="Ort" required>
            </div>
            <div>
                <label for="form1-deliveryAddress-phone" class="small">Telefonnummer*</label>
                <input id="form1-deliveryAddress-phone" class="input" type="tel" v-model="form1.deliveryAddress.phone" placeholder="Telefonnummer" required>
            </div>
            <div>
                <label for="form1-deliveryAddress-email" class="small">Mailadresse*</label>
                <input id="form1-deliveryAddress-email" class="input" type="email" v-model="form1.deliveryAddress.email" placeholder="Mailadresse" required>
            </div>
            <p class="subheadline">Rechnungsanschrift</p>
            <div>
                <label for="form1-billingAddress-company" class="small">Firmenname</label>
                <input id="form1-billingAddress-company" class="input" type="text" v-model="form1.billingAddress.company" placeholder="Firmenname">
            </div>
            <div>
                <label for="form1-billingAddress-firstName" class="small">Vorname*</label>
                <input id="form1-billingAddress-firstName" class="input" type="text" v-model="form1.billingAddress.firstName" placeholder="Vorname" required>
            </div>
            <div>
                <label for="form1-billingAddress-lastName" class="small">Nachname*</label>
                <input id="form1-billingAddress-lastName" class="input" type="text" v-model="form1.billingAddress.lastName" placeholder="Nachname" required>
            </div>
            <div>
                <label for="form1-billingAddress-address" class="small">Straße und Hausnummer*</label>
                <input id="form1-billingAddress-address" class="input" type="text" v-model="form1.billingAddress.address" placeholder="Straße und Hausnummer" required>
            </div>
            <div>
                <label for="form1-billingAddress-zipCode" class="small">Postleitzahl*</label>
                <input id="form1-billingAddress-zipCode" class="input" type="text" v-model="form1.billingAddress.zipCode" placeholder="Postleitzahl" required>
            </div>
            <div>
                <label for="form1-billingAddress-city" class="small">Ort*</label>
                <input id="form1-billingAddress-city" class="input" type="text" v-model="form1.billingAddress.city" placeholder="Ort" required>
            </div>
            <div>
                <label for="form1-billingAddress-phone" class="small">Telefonnummer*</label>
                <input id="form1-billingAddress-phone" class="input" type="text" v-model="form1.billingAddress.phone" placeholder="Telefonnummer" required>
            </div>
            <div>
                <label for="form1-billingAddress-email" class="small">Mailadresse*</label>
                <input id="form1-billingAddress-email" class="input" type="text" v-model="form1.billingAddress.email" placeholder="Mailadresse" required>
            </div>
            <p class="subheadline">Zahlungsweise</p>
            <div class="select">
                <select v-model="payment1" required @change="changePayment">
                    <option disabled value="">Bitte auswählen</option>
                    <option value="1">Wöchentlich Lastschrift</option>
                    <option value="2">Wöchentlich Überweisung</option>
                    <option value="3">Monatlich Lastschrift</option>
                </select>
            </div>
            <div v-if="form1.payment.type===1">
                <p class="subheadline">Bankdaten</p>
                <div>
                    <label for="form1-bank-accountOwner" class="small">Kontoinhaber*</label>
                    <input id="form1-bank-accountOwner" class="input" type="text" v-model="form1.bank.accountOwner" placeholder="Kontoinhaber" required>
                </div>
                <div>
                    <label for="form1-bank-bankName" class="small">Name der Bank*</label>
                    <input id="form1-bank-bankName" class="input" type="text" v-model="form1.bank.bankName" placeholder="Name der Bank" required>
                </div>
                <div>
                    <label for="form1-bank-bic" class="small">BIC*</label>
                    <input id="form1-bank-bic" class="input" type="text" v-model="form1.bank.bic" placeholder="BIC" required>
                </div>
                <div>
                    <label for="form1-bank-iban" class="small">IBAN*</label>
                    <input id="form1-bank-iban" class="input" type="text" v-model="form1.bank.iban" placeholder="IBAN" required>
                </div>
            </div>
            <p class="subheadline">Lieferinformationen und Passwort</p>
            <div>
                <label for="form1-password" class="small">Passwort*</label>
                <input id="form1-password"
                       class="input"
                       type="password"
                       v-model="form1.password"
                       placeholder="Passwort"
                       :class="{'is-danger':passwordError}"
                       @blur="validatePassword(form1)"
                       required
                >
            </div>
            <div>
                <label for="form1-passwordRepeat" class="small">Passwort Wiederholung*</label>
                <input @blur="checkSamePassword(form1)"
                       id="form1-passwordRepeat"
                       class="input"
                       type="password"
                       v-model="form1.passwordRepeat"
                       placeholder="Passwort Wiederholung"
                       :class="{'is-danger':passwordError}"
                       required
                >
                <span class="is-size-7 has-text-danger" v-if="passwordError">{{ passwordError }}</span>
            </div>
            <div>
                <label for="form1-deliveryInformation" class="small">Lieferinformationen wie Stockwerk, Hinterhaus oder Ähnliches</label>
                <textarea id="form1-deliveryInformation" class="textarea" v-model="form1.deliveryInformation" placeholder="Lieferinformationen wie Stockwerk, Hinterhaus oder Ähnliches" />
            </div>
            <p class="subheadline">E-Mail-Einstellungen</p>
            <div>
                <label for="form1-billingPerEmail" class="checkbox">
                    <input type="checkbox" id="form1-billingPerEmail" value="true" v-model="form1.billingPerEmail" />
                    Rechnung kostenfrei per E-Mail erhalten
                </label>
            </div>
            <div>
                <label for="form1-newsletter" class="checkbox">
                    <input type="checkbox" id="form1-newsletter" value="true" v-model="form1.newsletter" />
                    Ich willige ein, dass gourmetta regelmäßig News zu Aktionswochen und Angeboten sowie Informationen zum Unternehmen an meine angegebene E-Mail-Adresse schickt
                </label>
            </div>
            <p class="subheadline">Wie sind Sie auf uns Aufmerksam geworden?</p>
            <div class="select">
                <select v-model="form1.attention.type">
                    <option disabled value="">Bitte auswählen</option>
                    <option value="1">Homepage / Google und ähnliche Suchmaschinen</option>
                    <option value="2">Social Media</option>
                    <option value="3">Freunden & Kollegen</option>
                    <option value="4">Fahrzeug gesehen</option>
                    <option value="5">Außendienst</option>
                    <option value="6">Flyer</option>
                    <option value="7">Schule/Kita ist in der Versorgung</option>
                    <option value="8">Sonstiges</option>
                </select>
            </div>
            <div v-if="form1.attention.type==='8'">
                <label for="form1-attention-additional" class="small">Sonstiges</label>
                <textarea id="form1-attention-additional" v-model="form1.attention.additional" class="textarea" />
            </div>
            <div v-if="form1.alreadyCollegues==='false'">
                <p class="subheadline">Denken Sie auch andere Kollegen hätten Interesse an der Belieferung?</p>
                <div class="control">
                    <label for="form1-colleguesInterested-yes" class="radio">
                        <input type="radio" id="form1-colleguesInterested-yes" value="true" v-model="form1.colleguesInterested" />
                        Ja
                    </label>
                    <label for="form1-colleguesInterested-no" class="radio">
                        <input type="radio" id="form1-colleguesInterested-no" value="false" v-model="form1.colleguesInterested" />
                        Nein
                    </label>
                </div>
            </div>
            <hr>
            <div>
                <label for="form1-acceptGtc" class="checkbox">
                    <input type="checkbox" id="form1-acceptGtc" value="true" v-model="form1.acceptGtc"  required/>
                    Hiermit bestätige ich, dass ich die <a href="https://gourmetta.de/agb/" target="_blank">AGBs</a>, <a href="https://gourmetta.de/datenschutz/" target="_blank">Widerrufserklärung</a> und die <a href="https://gourmetta.de/datenschutz/" target="_blank">Datenschutzbestimmung</a> akzeptiere
                </label>
            </div>
        </div>
        <div v-if="registrationType==2">
            <p class="subheadline">Lieferadresse</p>
            <div>
                <label for="form2-deliveryAddress-firstName" class="small">Vorname*</label>
                <input id="form2-deliveryAddress-firstName" class="input" type="text" v-model="form2.deliveryAddress.firstName" placeholder="Vorname" required>
            </div>
            <div>
                <label for="form2-deliveryAddress-lastName" class="small">Nachname*</label>
                <input id="form2-deliveryAddress-lastName" class="input" type="text" v-model="form2.deliveryAddress.lastName" placeholder="Nachname" required>
            </div>
            <div>
                <label for="form2-deliveryAddress-address" class="small">Straße und Hausnummer*</label>
                <input id="form2-deliveryAddress-address" class="input" type="text" v-model="form2.deliveryAddress.address" placeholder="Straße und Hausnummer" required>
            </div>
            <div>
                <label for="form2-deliveryAddress-zipCode" class="small">Postleitzahl*</label>
                <input id="form2-deliveryAddress-zipCode" class="input" type="text" v-model="form2.deliveryAddress.zipCode" placeholder="Postleitzahl" required>
            </div>
            <div>
                <label for="form2-deliveryAddress-city" class="small">Ort*</label>
                <input id="form2-deliveryAddress-city" class="input" type="text" v-model="form2.deliveryAddress.city" placeholder="Ort" required>
            </div>
            <div>
                <label for="form2-deliveryAddress-phone" class="small">Telefonnummer*</label>
                <input id="form2-deliveryAddress-phone" class="input" type="tel" v-model="form2.deliveryAddress.phone" placeholder="Telefonnummer" required>
            </div>
            <div>
                <label for="form2-deliveryAddress-email" class="small">Mailadresse*</label>
                <input id="form2-deliveryAddress-email" class="input" type="email" v-model="form2.deliveryAddress.email" placeholder="Mailadresse" required>
            </div>
            <p class="subheadline">Abweichende Rechnungsanschrift</p>
            <div class="control">
                <label for="form2-addressSwitch-yes" class="radio">
                    <input type="radio" id="form2-addressSwitch-yes" value="true" v-model="addressSwitch" />
                    Ja
                </label>
                <label for="form2-addressSwitch-no" class="radio">
                    <input type="radio" id="form2-addressSwitch-no" value="false" v-model="addressSwitch" />
                    Nein
                </label>
            </div>
            <div v-if="addressSwitch==='true'">
                <p class="subheadline">Rechnungsanschrift</p>
                <div>
                    <label for="form2-billingAddress-firstName" class="small">Vorname*</label>
                    <input id="form2-billingAddress-firstName" class="input" type="text" v-model="form2.billingAddress.firstName" placeholder="Vorname" required>
                </div>
                <div>
                    <label for="form2-billingAddress-lastName" class="small">Nachname*</label>
                    <input id="form2-billingAddress-lastName" class="input" type="text" v-model="form2.billingAddress.lastName" placeholder="Nachname" required>
                </div>
                <div>
                    <label for="form2-billingAddress-address" class="small">Straße und Hausnummer*</label>
                    <input id="form2-billingAddress-address" class="input" type="text" v-model="form2.billingAddress.address" placeholder="Straße und Hausnummer" required>
                </div>
                <div>
                    <label for="form2-billingAddress-zipCode" class="small">Postleitzahl*</label>
                    <input id="form2-billingAddress-zipCode" class="input" type="text" v-model="form2.billingAddress.zipCode" placeholder="Postleitzahl" required>
                </div>
                <div>
                    <label for="form2-billingAddress-city" class="small">Ort*</label>
                    <input id="form2-billingAddress-city" class="input" type="text" v-model="form2.billingAddress.city" placeholder="Ort" required>
                </div>
                <div>
                    <label for="form2-billingAddress-phone" class="small">Telefonnummer*</label>
                    <input id="form2-billingAddress-phone" class="input" type="text" v-model="form2.billingAddress.phone" placeholder="Telefonnummer" required>
                </div>
                <div>
                    <label for="form2-billingAddress-email" class="small">Mailadresse*</label>
                    <input id="form2-billingAddress-email" class="input" type="text" v-model="form2.billingAddress.email" placeholder="Mailadresse" required>
                </div>
            </div>
            <p class="subheadline">Zahlungsweise</p>
            <div class="select">
                <select v-model="payment2" required @change="changePayment">
                    <option disabled value="">Bitte auswählen</option>
                    <option value="1">14 tägige Lastschrift</option>
                    <option value="2">14 tägige Überweisung</option>
                    <option value="3">Monatlich Lastschrift</option>
                </select>
            </div>
            <div v-if="form2.payment.type===1">
                <p class="subheadline">Bankdaten</p>
                <div>
                    <label for="form2-bank-accountOwner" class="small">Kontoinhaber*</label>
                    <input id="form2-bank-accountOwner" class="input" type="text" v-model="form2.bank.accountOwner" placeholder="Kontoinhaber" required>
                </div>
                <div>
                    <label for="form2-bank-bankName" class="small">Name der Bank*</label>
                    <input id="form2-bank-bankName" class="input" type="text" v-model="form2.bank.bankName" placeholder="Name der Bank" required>
                </div>
                <div>
                    <label for="form2-bank-bic" class="small">BIC*</label>
                    <input id="form2-bank-bic" class="input" type="text" v-model="form2.bank.bic" placeholder="BIC" required>
                </div>
                <div>
                    <label for="form2-bank-iban" class="small">IBAN*</label>
                    <input id="form2-bank-iban" class="input" type="text" v-model="form2.bank.iban" placeholder="IBAN" required>
                </div>
            </div>
            <p class="subheadline">Lieferinformationen und Passwort</p>
            <div>
                <label for="form2-password" class="small">Passwort*</label>
                <input id="form2-password"
                       class="input"
                       type="password"
                       v-model="form2.password"
                       placeholder="Passwort"
                       :class="{'is-danger':passwordError}"
                       @blur="validatePassword(form2)"
                       required
                >
            </div>
            <div>
                <label for="form2-passwordRepeat" class="small">Passwort Wiederholung*</label>
                <input @blur="checkSamePassword(form2)"
                       id="form2-passwordRepeat"
                       class="input"
                       type="password"
                       v-model="form2.passwordRepeat"
                       placeholder="Passwort Wiederholung"
                       :class="{'is-danger':passwordError}"
                       required
                >
                <span class="is-size-7 has-text-danger" v-if="passwordError">{{ passwordError }}</span>
            </div>
            <div>
                <label for="form2-deliveryInformation" class="small">Lieferinformationen wie Stockwerk, Hinterhaus oder Ähnliches</label>
                <textarea id="form2-deliveryInformation" class="textarea" v-model="form2.deliveryInformation" placeholder="Lieferinformationen wie Stockwerk, Hinterhaus oder Ähnliches" />
            </div>
            <p class="subheadline">E-Mail-Einstellungen</p>
            <div>
                <label for="form2-billingPerEmail" class="checkbox">
                    <input type="checkbox" id="form2-billingPerEmail" value="true" v-model="form2.billingPerEmail" />
                    Rechnung kostenfrei per E-Mail erhalten
                </label>
            </div>
            <div>
                <label for="form2-newsletter" class="checkbox">
                    <input type="checkbox" id="form2-newsletter" value="true" v-model="form2.newsletter" />
                    Ich willige ein, dass gourmetta regelmäßig News zu Aktionswochen und Angeboten sowie Informationen zum Unternehmen an meine angegebene E-Mail-Adresse schickt
                </label>
            </div>
            <p class="subheadline">Wie sind Sie auf uns Aufmerksam geworden?</p>
            <div class="select">
                <select v-model="form2.attention.type">
                    <option disabled value="">Bitte auswählen</option>
                    <option value="1">Homepage / Google und ähnliche Suchmaschinen</option>
                    <option value="2">Social Media</option>
                    <option value="3">Freunden & Kollegen</option>
                    <option value="4">Fahrzeug gesehen</option>
                    <option value="5">Außendienst</option>
                    <option value="6">Flyer</option>
                    <option value="7">Schule/Kita ist in der Versorgung</option>
                    <option value="8">Sonstiges</option>
                </select>
            </div>
            <div v-if="form2.attention.type==='8'">
                <label for="form2-attention-additional" class="small">Sonstiges</label>
                <textarea id="form2-attention-additional" v-model="form2.attention.additional" class="textarea" />
            </div>
            <hr>
            <div>
                <label for="form2-acceptGtc" class="checkbox">
                    <input type="checkbox" id="form2-acceptGtc" value="true" v-model="form2.acceptGtc"  required/>
                    Hiermit bestätige ich, dass ich die <a href="https://gourmetta.de/agb/" target="_blank">AGBs</a>, <a href="https://gourmetta.de/datenschutz/" target="_blank">Widerrufserklärung</a> und die <a href="https://gourmetta.de/datenschutz/" target="_blank">Datenschutzbestimmung</a> akzeptiere
                </label>
            </div>
        </div>
        <div v-if="registrationType==3">
            <p class="subheadline">Versorgung in welcher Schule</p>
            <div>
                <label for="form3-deliveryAddress-company" class="small">Name der Schule*</label>
                <input id="form3-deliveryAddress-company" class="input" type="text" v-model="form3.deliveryAddress.company" placeholder="Name der Schule" required>
            </div>
            <div>
                <label for="form3-deliveryAddress-address" class="small">Straße und Hausnummer der Schule*</label>
                <input id="form3-deliveryAddress-address" class="input" type="text" v-model="form3.deliveryAddress.address" placeholder="Straße und Hausnummer der Schule" required>
            </div>
            <div>
                <label for="form3-deliveryAddress-zipCode" class="small">Postleitzahl der Schule*</label>
                <input id="form3-deliveryAddress-zipCode" class="input" type="text" v-model="form3.deliveryAddress.zipCode" placeholder="Postleitzahl der Schule" required>
            </div>
            <div>
                <label for="form3-deliveryAddress-city" class="small">Ort der Schule*</label>
                <input id="form3-deliveryAddress-city" class="input" type="text" v-model="form3.deliveryAddress.city" placeholder="Ort der Schule" required>
            </div>
            <p class="subheadline">Daten der Person die an der Essenversorgung teilnimmt</p>
            <div class="control">
                <label for="form3-isNotAdult-yes" class="radio">
                    <input type="radio" id="form3-isNotAdult-yes" value="true" v-model="form3.isNotAdult" />
                    Kind
                </label>
                <label for="form3-isNotAdult-no" class="radio">
                    <input type="radio" id="form3-isNotAdult-no" value="false" v-model="form3.isNotAdult" />
                    Lehrer
                </label>
            </div>
            <div v-if="form3.isNotAdult==='true'">
                <div>
                    <label for="form3-child-firstName" class="small">Vorname Kind*</label>
                    <input id="form3-child-firstName" class="input" type="text" v-model="form3.child.firstName" placeholder="Vorname Kind" required>
                </div>
                <div>
                    <label for="form3-child-lastName" class="small">Nachname Kind*</label>
                    <input id="form3-child-lastName" class="input" type="text" v-model="form3.child.lastName" placeholder="Nachname Kind" required>
                </div>
            </div>
            <p class="subheadline">Rechnungsanschrift<span v-if="form3.isNotAdult==='true'"> (Erziehungsberechtigter)</span><span v-else> (Lehrer)</span></p>
            <div>
                <label for="form3-billingAddress-firstName" class="small">Vorname*</label>
                <input id="form3-billingAddress-firstName" class="input" type="text" v-model="form3.billingAddress.firstName" placeholder="Vorname" required>
            </div>
            <div>
                <label for="form3-billingAddress-lastName" class="small">Nachname*</label>
                <input id="form3-billingAddress-lastName" class="input" type="text" v-model="form3.billingAddress.lastName" placeholder="Nachname" required>
            </div>
            <div>
                <label for="form3-billingAddress-address" class="small">Straße und Hausnummer*</label>
                <input id="form3-billingAddress-address" class="input" type="text" v-model="form3.billingAddress.address" placeholder="Straße und Hausnummer" required>
            </div>
            <div>
                <label for="form3-billingAddress-zipCode" class="small">Postleitzahl*</label>
                <input id="form3-billingAddress-zipCode" class="input" type="text" v-model="form3.billingAddress.zipCode" placeholder="Postleitzahl" required>
            </div>
            <div>
                <label for="form3-billingAddress-city" class="small">Ort*</label>
                <input id="form3-billingAddress-city" class="input" type="text" v-model="form3.billingAddress.city" placeholder="Ort" required>
            </div>
            <div>
                <label for="form3-billingAddress-phone" class="small">Telefonnummer*</label>
                <input id="form3-billingAddress-phone" class="input" type="text" v-model="form3.billingAddress.phone" placeholder="Telefonnummer" required>
            </div>
            <div>
                <label for="form3-billingAddress-email" class="small">Mailadresse*</label>
                <input id="form3-billingAddress-email" class="input" type="text" v-model="form3.billingAddress.email" placeholder="Mailadresse" required>
            </div>
            <p class="subheadline">Zahlungsweise</p>
            <div class="select">
                <select v-model="payment3" required @change="changePayment">
                    <option disabled value="">Bitte auswählen</option>
                    <option value="1">Monatlich Lastschrift</option>
                    <option value="2">Monatlich Überweisung</option>
                </select>
            </div>
            <div v-if="form3.payment.type===1">
                <p class="subheadline">Bankdaten</p>
                <div>
                    <label for="form3-bank-accountOwner" class="small">Kontoinhaber*</label>
                    <input id="form3-bank-accountOwner" class="input" type="text" v-model="form3.bank.accountOwner" placeholder="Kontoinhaber" required>
                </div>
                <div>
                    <label for="form3-bank-bankName" class="small">Name der Bank*</label>
                    <input id="form3-bank-bankName" class="input" type="text" v-model="form3.bank.bankName" placeholder="Name der Bank" required>
                </div>
                <div>
                    <label for="form3-bank-bic" class="small">BIC*</label>
                    <input id="form3-bank-bic" class="input" type="text" v-model="form3.bank.bic" placeholder="BIC" required>
                </div>
                <div>
                    <label for="form3-bank-iban" class="small">IBAN*</label>
                    <input id="form3-bank-iban" class="input" type="text" v-model="form3.bank.iban" placeholder="IBAN" required>
                </div>
            </div>
            <p class="subheadline">Lieferinformationen und Passwort</p>
            <div>
                <label for="form3-password" class="small">Passwort*</label>
                <input id="form3-password"
                       class="input"
                       type="password"
                       v-model="form3.password"
                       placeholder="Passwort"
                       :class="{'is-danger':passwordError}"
                       @blur="validatePassword(form2)"
                       required
                >
            </div>
            <div>
                <label for="form3-passwordRepeat" class="small">Passwort Wiederholung*</label>
                <input @blur="checkSamePassword(form3)"
                       id="form3-passwordRepeat"
                       class="input"
                       type="password"
                       v-model="form3.passwordRepeat"
                       placeholder="Passwort Wiederholung"
                       :class="{'is-danger':passwordError}"
                       required
                >
                <span class="is-size-7 has-text-danger" v-if="passwordError">{{ passwordError }}</span>
            </div>
            <p class="subheadline">E-Mail-Einstellungen</p>
            <div>
                <label for="form3-billingPerEmail" class="checkbox">
                    <input type="checkbox" id="form3-billingPerEmail" value="true" v-model="form3.billingPerEmail" />
                    Rechnung kostenfrei per E-Mail erhalten
                </label>
            </div>
            <div>
                <label for="form3-newsletter" class="checkbox">
                    <input type="checkbox" id="form3-newsletter" value="true" v-model="form3.newsletter" />
                    Ich willige ein, dass gourmetta regelmäßig News zu Aktionswochen und Angeboten sowie Informationen zum Unternehmen an meine angegebene E-Mail-Adresse schickt
                </label>
            </div>
            <p class="subheadline">Wie sind Sie auf uns Aufmerksam geworden?</p>
            <div class="select">
                <select v-model="form3.attention.type">
                    <option disabled value="">Bitte auswählen</option>
                    <option value="1">Homepage / Google und ähnliche Suchmaschinen</option>
                    <option value="2">Social Media</option>
                    <option value="3">Freunden & Kollegen</option>
                    <option value="4">Fahrzeug gesehen</option>
                    <option value="5">Außendienst</option>
                    <option value="6">Flyer</option>
                    <option value="7">Schule/Kita ist in der Versorgung</option>
                    <option value="8">Sonstiges</option>
                </select>
            </div>
            <div v-if="form3.attention.type==='8'">
                <label for="form3-attention-additional" class="small">Sonstiges</label>
                <textarea id="form3-attention-additional" v-model="form3.attention.additional" class="textarea" />
            </div>
            <hr>
            <div>
                <label for="form3-acceptGtc" class="checkbox">
                    <input type="checkbox" id="form3-acceptGtc" value="true" v-model="form3.acceptGtc"  required/>
                    Hiermit bestätige ich, dass ich die <a href="https://gourmetta.de/agb/" target="_blank">AGBs</a>, <a href="https://gourmetta.de/datenschutz/" target="_blank">Widerrufserklärung</a> und die <a href="https://gourmetta.de/datenschutz/" target="_blank">Datenschutzbestimmung</a> akzeptiere
                </label>
            </div>
        </div>
        <div v-if="registrationType==4">
            <p class="subheadline">Versorgung in welcher Kita</p>
            <div>
                <label for="form4-deliveryAddress-company" class="small">Name der Kita*</label>
                <input id="form4-deliveryAddress-company" class="input" type="text" v-model="form4.deliveryAddress.company" placeholder="Name der Kita" required>
            </div>
            <div>
                <label for="form4-deliveryAddress-address" class="small">Straße und Hausnummer der Kita*</label>
                <input id="form4-deliveryAddress-address" class="input" type="text" v-model="form4.deliveryAddress.address" placeholder="Straße und Hausnummer der Kita" required>
            </div>
            <div>
                <label for="form4-deliveryAddress-zipCode" class="small">Postleitzahl der Kita*</label>
                <input id="form4-deliveryAddress-zipCode" class="input" type="text" v-model="form4.deliveryAddress.zipCode" placeholder="Postleitzahl der Kita" required>
            </div>
            <div>
                <label for="form4-deliveryAddress-city" class="small">Ort der Kita*</label>
                <input id="form4-deliveryAddress-city" class="input" type="text" v-model="form4.deliveryAddress.city" placeholder="Ort der Kita" required>
            </div>
            <p class="subheadline">Daten der Person die an der Essenversorgung teilnimmt</p>
            <div class="control">
                <label for="form4-isNotAdult-yes" class="radio">
                    <input type="radio" id="form4-isNotAdult-yes" value="true" v-model="form4.isNotAdult" />
                    Kind
                </label>
                <label for="form4-isNotAdult-no" class="radio">
                    <input type="radio" id="form4-isNotAdult-no" value="false" v-model="form4.isNotAdult" />
                    Erzieher
                </label>
            </div>
            <div v-if="form4.isNotAdult==='true'">
                <div>
                    <label for="form4-child-firstName" class="small">Vorname Kind*</label>
                    <input id="form4-child-firstName" class="input" type="text" v-model="form4.child.firstName" placeholder="Vorname Kind" required>
                </div>
                <div>
                    <label for="form4-child-lastName" class="small">Nachname Kind*</label>
                    <input id="form4-child-lastName" class="input" type="text" v-model="form4.child.lastName" placeholder="Nachname Kind" required>
                </div>
            </div>
            <p class="subheadline">Rechnungsanschrift<span v-if="form4.isNotAdult==='true'"> (Erziehungsberechtigter)</span><span v-else> (Erzieher)</span></p>
            <div>
                <label for="form4-billingAddress-firstName" class="small">Vorname*</label>
                <input id="form4-billingAddress-firstName" class="input" type="text" v-model="form4.billingAddress.firstName" placeholder="Vorname" required>
            </div>
            <div>
                <label for="form4-billingAddress-lastName" class="small">Nachname*</label>
                <input id="form4-billingAddress-lastName" class="input" type="text" v-model="form4.billingAddress.lastName" placeholder="Nachname" required>
            </div>
            <div>
                <label for="form4-billingAddress-address" class="small">Straße und Hausnummer*</label>
                <input id="form4-billingAddress-address" class="input" type="text" v-model="form4.billingAddress.address" placeholder="Straße und Hausnummer" required>
            </div>
            <div>
                <label for="form4-billingAddress-zipCode" class="small">Postleitzahl*</label>
                <input id="form4-billingAddress-zipCode" class="input" type="text" v-model="form4.billingAddress.zipCode" placeholder="Postleitzahl" required>
            </div>
            <div>
                <label for="form4-billingAddress-city" class="small">Ort*</label>
                <input id="form4-billingAddress-city" class="input" type="text" v-model="form4.billingAddress.city" placeholder="Ort" required>
            </div>
            <div>
                <label for="form4-billingAddress-phone" class="small">Telefonnummer*</label>
                <input id="form4-billingAddress-phone" class="input" type="text" v-model="form4.billingAddress.phone" placeholder="Telefonnummer" required>
            </div>
            <div>
                <label for="form4-billingAddress-email" class="small">Mailadresse*</label>
                <input id="form4-billingAddress-email" class="input" type="text" v-model="form4.billingAddress.email" placeholder="Mailadresse" required>
            </div>
            <p class="subheadline">Zahlungsweise</p>
            <div class="select">
                <select v-model="payment4" required @change="changePayment">
                    <option disabled value="">Bitte auswählen</option>
                    <option value="1">Monatlich Lastschrift</option>
                    <option value="2">Monatlich Überweisung</option>
                </select>
            </div>
            <div v-if="form4.payment.type===1">
                <p class="subheadline">Bankdaten</p>
                <div>
                    <label for="form4-bank-accountOwner" class="small">Kontoinhaber*</label>
                    <input id="form4-bank-accountOwner" class="input" type="text" v-model="form4.bank.accountOwner" placeholder="Kontoinhaber" required>
                </div>
                <div>
                    <label for="form4-bank-bankName" class="small">Name der Bank*</label>
                    <input id="form4-bank-bankName" class="input" type="text" v-model="form4.bank.bankName" placeholder="Name der Bank" required>
                </div>
                <div>
                    <label for="form4-bank-bic" class="small">BIC*</label>
                    <input id="form4-bank-bic" class="input" type="text" v-model="form4.bank.bic" placeholder="BIC" required>
                </div>
                <div>
                    <label for="form4-bank-iban" class="small">IBAN*</label>
                    <input id="form4-bank-iban" class="input" type="text" v-model="form4.bank.iban" placeholder="IBAN" required>
                </div>
            </div>
            <p class="subheadline">Lieferinformationen und Passwort</p>
            <div>
                <label for="form4-password" class="small">Passwort*</label>
                <input id="form4-password"
                       class="input"
                       type="password"
                       v-model="form4.password"
                       placeholder="Passwort"
                       :class="{'is-danger':passwordError}"
                       @blur="validatePassword(form2)"
                       required
                >
            </div>
            <div>
                <label for="form4-passwordRepeat" class="small">Passwort Wiederholung*</label>
                <input @blur="checkSamePassword(form4)"
                       id="form4-passwordRepeat"
                       class="input"
                       type="password"
                       :class="{'is-danger':passwordError}"
                       v-model="form4.passwordRepeat"
                       placeholder="Passwort Wiederholung"
                       required
                >
                <span class="is-size-7 has-text-danger" v-if="passwordError">{{ passwordError }}</span>
            </div>
            <p class="subheadline">E-Mail-Einstellungen</p>
            <div>
                <label for="form4-billingPerEmail" class="checkbox">
                    <input type="checkbox" id="form4-billingPerEmail" value="true" v-model="form4.billingPerEmail" />
                    Rechnung kostenfrei per E-Mail erhalten
                </label>
            </div>
            <div>
                <label for="form4-newsletter" class="checkbox">
                    <input type="checkbox" id="form4-newsletter" value="true" v-model="form4.newsletter" />
                    Ich willige ein, dass gourmetta regelmäßig News zu Aktionswochen und Angeboten sowie Informationen zum Unternehmen an meine angegebene E-Mail-Adresse schickt
                </label>
            </div>
            <p class="subheadline">Eingewöhnungszeit von bis</p>
            <div>
                <label for="form4-startDate" class="small">Teilnahme der Essenversorgung ab*</label>
                <input id="form4-startDate" class="input" type="date" v-model="form4.startDate" required>
                <p>
                    Hinweis: Ab diesem Tag wird dann die Dauerbestellung in Ihrem Kundenkonto hinterlegt
                </p>
            </div>
            <hr>
            <div>
                <label for="form4-acceptGtc" class="checkbox">
                    <input type="checkbox" id="form4-acceptGtc" value="true" v-model="form4.acceptGtc"  required/>
                    Hiermit bestätige ich, dass ich die <a href="https://gourmetta.de/agb/" target="_blank">AGBs</a>, <a href="https://gourmetta.de/datenschutz/" target="_blank">Widerrufserklärung</a> und die <a href="https://gourmetta.de/datenschutz/" target="_blank">Datenschutzbestimmung</a> akzeptiere
                </label>
            </div>
        </div>
        <div class="subnote-send">
            <p class="subnote">
                Um ein rechtsgültiges Vertragsverhältnis herstellen zu können, benötigen wir Ihre persönlichen Informationen. Diese Angaben werden natürlich vertraulich behandelt und sind ausschließlich für den internen Gebrauch bestimmt. Bei weiteren Fragen bitte unsere Datenschutzrichtlinie durchlesen.
            </p>
            <Button text="Senden"></Button>
        </div>
    </form>
</template>

<script>
import Button from '@/views/components/ui/Button.vue'

export default {
    name: 'RegisterForm',
    components: {
        Button
    },
    mounted() {
        this.getData()
    },
    methods: {
        validatePassword(form) {
            if (form.password[0] === '#' || form.passwordRepeat[0] === '#') {
                this.isPasswordValid = false
                this.passwordError = 'Das Passwort darf nicht mit # beginnen'
                return
            }

            if (form.password.length < 8 || form.passwordRepeat.length < 8) {
                this.isPasswordValid = false
                this.passwordError = 'Das Passwort muss mind. 8 Zeichen lang sein'
                return
            }

            this.isPasswordValid = true
            this.passwordError = ''
        },
        checkSamePassword(form) {
            if (form.password !== form.passwordRepeat) {
                this.isPasswordValid = false
                this.passwordError = 'Die beide Passwörter müssen identisch sein'
                return
            }

            this.validatePassword(form)

            this.isPasswordValid = true
            this.passwordError = ''
        },
        getData() {
            let form = this.$store.getters.getFormData

            if (form) {
                if (this.registrationType==='1') {
                    this.form1 = form
                    if (form.payment.type === 1 && form.payment.period === 1) {
                        this.payment1 = '1'
                    }
                    if (form.payment.type === 2 && form.payment.period === 1) {
                        this.payment1 = '2'
                    }
                    if (form.payment.type === 1 && form.payment.period === 2) {
                        this.payment1 = '3'
                    }
                }
                if (this.registrationType==='2') {
                    this.form2 = form
                    if (form.payment.type === 1 && form.payment.period === 3) {
                        this.payment2 = '1'
                    }
                    if (form.payment.type === 2 && form.payment.period === 3) {
                        this.payment2 = '2'
                    }
                    if (form.payment.type === 1 && form.payment.period === 2) {
                        this.payment2 = '3'
                    }
                }
                if (this.registrationType==='3') {
                    this.form3 = form
                    if (form.payment.type === 1 && form.payment.period === 2) {
                        this.payment3 = '1'
                    }
                    if (form.payment.type === 2 && form.payment.period === 2) {
                        this.payment3 = '2'
                    }
                }
                if (this.registrationType==='4') {
                    this.form4 = form
                    if (form.payment.type === 1 && form.payment.period === 2) {
                        this.payment4 = '1'
                    }
                    if (form.payment.type === 2 && form.payment.period === 2) {
                        this.payment4 = '2'
                    }
                }

            }
        },
        changePayment() {
            if (this.payment1 === '1') {
                this.form1.payment.type = 1
                this.form1.payment.period = 1
            }
            if (this.payment1 === '2') {
                this.form1.payment.type = 2
                this.form1.payment.period = 1
            }
            if (this.payment1 === '3') {
                this.form1.payment.type = 1
                this.form1.payment.period = 2
            }
            if (this.payment2 === '1') {
                this.form2.payment.type = 1
                this.form2.payment.period = 3
            }
            if (this.payment2 === '2') {
                this.form2.payment.type = 2
                this.form2.payment.period = 3
            }
            if (this.payment2 === '3') {
                this.form2.payment.type = 1
                this.form2.payment.period = 2
            }
            if (this.payment3 === '1') {
                this.form3.payment.type = 1
                this.form3.payment.period = 2
            }
            if (this.payment3 === '2') {
                this.form3.payment.type = 2
                this.form3.payment.period = 2
            }
            if (this.payment4 === '1') {
                this.form4.payment.type = 1
                this.form4.payment.period = 2
            }
            if (this.payment4 === '2') {
                this.form4.payment.type = 2
                this.form4.payment.period = 2
            }
        },
        submitForm() {
            if (this.passwordError) {
                return
            }
            this.saveFormData()
            this.$parent.addStep()
        },
        saveFormData() {
            if (this.registrationType==='1') {
                this.$store.commit('SET_FORM_DATA', this.form1)
            }
            if (this.registrationType==='2') {
                this.$store.commit('SET_FORM_DATA', this.form2)
            }
            if (this.registrationType==='3') {
                this.$store.commit('SET_FORM_DATA', this.form3)
            }
            if (this.registrationType==='4') {
                this.$store.commit('SET_FORM_DATA', this.form4)
            }
        }
    },
    data() {
        return {
            payment1: '',
            payment2: '',
            payment3: '',
            payment4: '',
            addressSwitch: 'false',
            form1: {
                password: '',
                passwordRepeat: '',
                deliveryInformation: '',
                billingPerEmail: '',
                newsletter: '',
                alreadyCollegues: 'false',
                colleguesInterested: 'false',
                acceptGtc: 'false',
                deliveryAddress: {
                    'company'  : '',
                    'lastName' : '',
                    'firstName': '',
                    'address'  : '',
                    'zipCode'  : '',
                    'city'     : '',
                    'email'    : '',
                    'phone'    : ''
                },
                billingAddress: {
                    'company'  : '',
                    'lastName' : '',
                    'firstName': '',
                    'address'  : '',
                    'zipCode'  : '',
                    'city'     : '',
                    'email'    : '',
                    'phone'    : ''
                },
                payment: {
                    type: '',
                    period: ''
                },
                bank: {
                    'accountOwner' : '',
                    'bankName'     : '',
                    'iban'         : '',
                    'bic'          : ''
                },
                attention: {
                    'type': '',
                    'additional': ''
                }
            },
            form2: {
                password: '',
                passwordRepeat: '',
                deliveryInformation: '',
                billingPerEmail: '',
                newsletter: '',
                acceptGtc: 'false',
                deliveryAddress: {
                    'lastName' : '',
                    'firstName': '',
                    'address'  : '',
                    'zipCode'  : '',
                    'city'     : '',
                    'email'    : '',
                    'phone'    : ''
                },
                billingAddress: {
                    'lastName' : '',
                    'firstName': '',
                    'address'  : '',
                    'zipCode'  : '',
                    'city'     : '',
                    'email'    : '',
                    'phone'    : ''
                },
                payment: {
                    type: '',
                    period: ''
                },
                bank: {
                    'accountOwner' : '',
                    'bankName'     : '',
                    'iban'         : '',
                    'bic'          : ''
                },
                attention: {
                    'type': '',
                    'additional': ''
                }
            },
            form3: {
                isNotAdult: 'false',
                password: '',
                passwordRepeat: '',
                billingPerEmail: '',
                newsletter: '',
                acceptGtc: 'false',
                deliveryAddress: {
                    'company'  : '',
                    'address'  : '',
                    'zipCode'  : '',
                    'city'     : ''
                },
                billingAddress: {
                    'lastName' : '',
                    'firstName': '',
                    'address'  : '',
                    'zipCode'  : '',
                    'city'     : '',
                    'email'    : '',
                    'phone'    : ''
                },
                child: {
                    'lastName' : '',
                    'firstName': ''
                },
                payment: {
                    type: '',
                    period: ''
                },
                bank: {
                    'accountOwner' : '',
                    'bankName'     : '',
                    'iban'         : '',
                    'bic'          : ''
                },
                attention: {
                    'type': '',
                    'additional': ''
                }
            },
            form4: {
                isNotAdult: 'false',
                password: '',
                passwordRepeat: '',
                startDate: '',
                billingPerEmail: '',
                newsletter: '',
                acceptGtc: 'false',
                deliveryAddress: {
                    'company'  : '',
                    'address'  : '',
                    'zipCode'  : '',
                    'city'     : ''
                },
                billingAddress: {
                    'lastName' : '',
                    'firstName': '',
                    'address'  : '',
                    'zipCode'  : '',
                    'city'     : '',
                    'email'    : '',
                    'phone'    : ''
                },
                child: {
                    'lastName' : '',
                    'firstName': ''
                },
                payment: {
                    type: '',
                    period: ''
                },
                bank: {
                    'accountOwner' : '',
                    'bankName'     : '',
                    'iban'         : '',
                    'bic'          : ''
                }
            },
            registrationType: sessionStorage.getItem('registrationType'),
            isPasswordValid: true,
            passwordError: ''
        }
    }
}
</script>