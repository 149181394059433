<template>
    <div class="container list-container is-fluid is-flex is-flex-direction-column">
        <h1>Mein Kundenkonto</h1>
        <div class="list-section">
            <div v-if="userData.lastName.length === 0">
                <p class="list-title">Name</p>
                <p>{{ userData.firstName }}</p>
            </div>
            <div v-else>
                <p class="list-title">Name, Vorname</p>
                <p>{{ userData.lastName }}, {{ userData.firstName }}</p>
            </div>
        </div>
        <div class="list-section">
            <p class="list-title">Kundennummer</p>
            <p>{{ customerNumber }}</p>
        </div>

        <div class="list-section">
            <p class="list-title">Lieferadresse</p>
            <p>{{ userData.firstName }} {{ userData.lastName }}</p>
            <p>{{ userData.address }}</p>
            <p>{{ userData.zipCode }} {{ userData.city }}</p>
        </div>

        <div class="list-section">
            <p class="list-title">Telefon</p>
            <p>{{ userData.phone }}</p>
        </div>

        <div class="list-section">
            <p class="list-title">E-Mail</p>
            <p>{{ userData.email }}</p>
        </div>

        <Button text="Passwort ändern" @click="$router.push('/passwort-aendern')"/>
    </div>
</template>

<script>
import Button from "@/views/components/ui/Button"

export default {
    name: 'UserProfile',
    components: {
      Button
    },
    data() {
        return {
            customerNumber: '',
            userData: {}
        }
    },
    beforeMount() {
        this.customerNumber = this.$store.getters.getUserList.customerNumber
        this.userData = this.$store.getters.getUserList.deliveryAddress
    }
}
</script>

<style lang="scss">
.list-container {
    min-height: calc(100vh - 200px);
    background-color: $secondary;

    h1 {
        font-size: $fontsize-headline5;
        font-weight: $font-weight-black;
        margin-top: 1rem;
    }
}

.list-section {
    margin: .5rem 0;
    .list-title {
        font-weight: $font-weight-bold
    }
}

@media all and (min-width: $desktop) {
    .container {
        &.space-for-footer {
            &::before {
                content: "";
                height: 0;
                box-shadow: none;
            }

            .container {
                &.header {
                    padding-top: 20px;
                }
            }

        }

    }
}
</style>
