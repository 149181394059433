<template>
    <div class="loader-container is-flex is-justify-content-center is-align-items-center">
        <div class="loader is-loading is-large"></div>
    </div>
</template>

<script>
export default {
    name: 'Loader'
}
</script>

<style lang="scss" scoped>
.loader-container {
    height: 50vh;
}

.loader {
    border: 3px solid $primary;
    border-right-color: transparent;
    border-top-color: transparent;
    height: 2em;
    width: 2em;
}
</style>