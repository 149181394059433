<template>
    <div class="header container is-fluid">
        <h1 class="menu-list-title pb-4">Warenkorb</h1>
        <UserName :is-cart="true"/>
    </div>
    <CartHeaderSummary :first-date="firstMealDate" :last-date="lastMealDate"/>
    <div class="container is-fluid" :class="{'desktop':viewport==='desktop'}" >
        <div v-if="isLoading">
            <Loader/>
        </div>
        <div v-else>
            <div v-if="orders.length > 0">
                <vue-collapsible-panel-group accordion
                                             base-color="#00000000"
                                             border-color="#00000000"
                                             bg-color-header="#00000000"
                                             bg-color-header-hover="#00000000"
                                             bg-color-header-active="#00000000">
                    <MenuDayList
                        v-for="(dayMeals, index) of orders"
                        :key="(index * Math.random() * 1000).toFixed(0)"
                        :meals-of-the-day="dayMeals"
                        :date="dayMeals.date"
                        :is-cart="true"
                        :expand="false"
                    />
                </vue-collapsible-panel-group>
            </div>
            <div v-else>
                Keine Bestellungen oder Änderungen vorhanden.<br><br>
            </div>
        </div>
    </div>
    <CartFooterSummary :is-loading="isLoading" />
</template>

<script>
import Loader from "@/views/components/ui/Loader"
import UserName from "@/views/components/ui/UserName"
import {VueCollapsiblePanelGroup} from '@dafcoe/vue-collapsible-panel'
import MenuDayList from "@/views/components/ui/MenuDayList"
import CartHeaderSummary from "@/views/components/ui/CartHeaderSummary";
import {getISOWeek, isFuture, parseISO, addWeeks, eachWeekOfInterval, startOfWeek, endOfWeek, format} from "date-fns";
import CartFooterSummary from "@/views/components/ui/CartFooterSummary";

export default {
    name: 'Cart',
    components: {
        CartFooterSummary,
        Loader,
        UserName,
        CartHeaderSummary,
        VueCollapsiblePanelGroup,
        MenuDayList
    },
    data() {
        return {
            isLoading: false,
            orders: [],
            activeUserUuid: '',
            date: new Date(),
            weekNumber: 0,
            firstMealDate: null,
            lastMealDate: null,
            mealChanges: [],
            usersWithPendingChanges: [],
            viewport: 'mobile'
        }
    },
    methods: {
        async getMissingOrder(from, to) {
            let missingOrder = await this.$store.dispatch('getWeekOrder', {
                from: format(from, 'yyyy-MM-dd'),
                to: format(to, 'yyyy-MM-dd'),
            })
            this.$store.commit('UPDATE_WEEK_ORDER_LIST', missingOrder)
        },
        async getCart() {
            this.isLoading = true
            this.firstMealDate = null
            this.lastMealDate = null
            this.mealChanges = []
            this.weekNumber = getISOWeek(this.date)
            const userUuid = this.$store.getters.getUserUuid
            let weekOrderList = await this.$store.getters.getWeekOrderList
            let orders = []
            let first = null
            let last = null

            let weekRange = eachWeekOfInterval({
                start: this.date,
                end: addWeeks(this.date, process.env.VUE_APP_FUTURE_WEEKS)
            }, {weekStartsOn: 1})

            for (const week of weekRange) {
                let kw = getISOWeek(week)

                if (!weekOrderList[userUuid] || !weekOrderList[userUuid][kw]) {
                    if (first === null) {
                        first = startOfWeek(week,{weekStartsOn: 1})
                    }
                    last = endOfWeek(week, {weekStartsOn: 1})
                }
            }

            if (first !== null) {
                await this.getMissingOrder(first, last)
            }

            weekOrderList = await this.$store.getters.getWeekOrderList

            for (const week of weekRange) {
                let kw = getISOWeek(week)

                if (weekOrderList[userUuid] && weekOrderList[userUuid][kw]) {
                    for (let day of weekOrderList[userUuid][kw].orderDays) {
                        if (day.quantity > 0 || day.orderedQuantity > 0) {
                            let newDay = {
                                date: day.date,
                                orderedQuantity: day.orderedQuantity,
                                quantity: day.quantity,
                                orderedMeals: []
                            }
                            for (let meal of day.orderedMeals) {
                                if (isFuture(parseISO(meal.meal.deadlineOrder)) && (meal.quantity > 0 || meal.orderedQuantity > 0)) {

                                    if (this.firstMealDate === null) {
                                        this.firstMealDate = meal.meal.date
                                    }
                                    if (meal.quantity !== meal.orderedQuantity) {
                                        newDay.orderedMeals.push(meal)
                                        this.mealChanges.push(meal.meal.id)
                                    }
                                    this.lastMealDate = meal.meal.date
                                }
                            }
                            if (newDay.orderedMeals.length > 0) {
                                orders.push(newDay)
                            }
                        }
                    }
                }
            }

            let payload = {
                modifiedMeals: this.mealChanges,
                userUuid: userUuid
            }

            this.$store.commit('SET_MODIFIED_MEALS', payload)
            this.$store.commit('SET_ORDER_LIST', orders)
            this.orders = orders
            this.isLoading = false
        },
        getViewport() {
            if (window.outerWidth < 1116 ) {
                return 'mobile'
            }
            return 'desktop'
        },
        handleWindowResize() {
            this.viewport = this.getViewport()
        },
        checkUsersWithChanges() {
            let userList = this.$store.getters.getUserList

            this.populateUserList(userList)

            for (let user of userList.accounts) {
                this.populateUserList(user)
            }
        },
        populateUserList(user) {
            if (user.modifiedMeals !== undefined && user.modifiedMeals.length > 0) {
                if (!this.usersWithPendingChanges.includes(user.uuid)) {
                    this.usersWithPendingChanges.push(user.uuid)
                }
            }

            if (user.modifiedMeals === undefined || user.modifiedMeals.length === 0) {
                this.usersWithPendingChanges = this.usersWithPendingChanges.filter(item => item !== user.uuid)
            }
        }
    },
    beforeMount() {
        this.getCart()
        this.viewport = this.getViewport()
    },
    watch: {
        "$store.state.userUuid"(userUuid) {
            this.activeUserUuid = userUuid
            this.getCart()
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowResize);
    }
}
</script>

<style lang="scss">
.hideHeaderImage {
    .header {
        background: none;
        padding-top: 20px;
    }
}

.cart {
    .header {
        .menu-list-title {
            font-weight: $font-weight-black;
            font-size: $font-size-xxlarge;
            margin-top: 0;
        }

        padding-bottom: 20px;

        .welcome,
        .sub-welcome{
            display: none;
        }

        .dropdown
        {
            &.select {
                button {
                    font-size: $fontsize-headline7;
                    height: 2rem;
                    min-height: 2rem;
                    width: auto;
                }
            }
        }

        .user-name {
            span {
                font-size: $fontsize-headline7;
            }

        }
    }

    &.container {
        &.main {
            min-height: 90vh;
        }
    }
}

.modal-message {
    text-align: center;
}

.amount-container {
    margin-bottom: 1rem;
}

@media all and (min-width: $desktop) {
    .desktop {
        .vcp__body-content {
            .menu-list-item {
                h3.menu-list-item__name {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    height:55px;
                    font-size: $font-size-medium;
                }
                span.menu-list-item__description {
                    height: 70px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                }
            }
            .amount-container {
                font-size: $font-size-medium;
            }
            .meal-cart-summary {
                font-size: $font-size-medium;

                .total-price-container {
                    p {
                        font-size: $font-size-medium;
                    }
                }
            }
        }
    }
}

@media all and (min-width: $fullhd) {
    .desktop {
        .vcp__body-content {
            .menu-list-item {
                h3.menu-list-item__name {
                    height:55px;
                }
                span.menu-list-item__description {
                    height: 70px;
                }
            }
        }
    }
}
</style>
