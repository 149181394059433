<template>
  <span :class="'is-flex is-align-items-center is-justify-content-flex-' + justify">
      <slot></slot>
  </span>
</template>

<script>
export default {
    name: 'HeaderItem',
    props: {
        justify: String
    }
}
</script>
