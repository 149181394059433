<template>
    <div class="sidebar-overlay" :class="{'active': isSidebarActive}" @click="closeSidebar"></div>
    <div
        class="sidebar-container is-flex is-flex-direction-column"
        :class="{'active': isSidebarActive}"
    >
        <div class="sidebar-header is-flex is-justify-content-space-between is-align-items-center">
            <img src="@/assets/images/logo_temp.svg" alt="gourmetta logo" class="logo">
            <button class="delete is-large is-black" aria-label="close" @click="closeSidebar"/>
        </div>
        <SidebarItem text="Speiseplan" to="/speiseplan" @click="closeSidebar"/>
        <SidebarItem text="Warenkorb" to="/warenkorb" @click="closeSidebar"/>
        <SidebarItem text="Bestellübersicht" to="/bestelluebersicht" @click="closeSidebar"/>
        <SidebarItem text="Kundenkonto" to="/kundenkonto" @click="closeSidebar"/>
        <SidebarItem text="Cookie Einstellungen" :is-button="true" @click="showCookieLayer"/>
        <Button text="Abmeldung" class="logout-button" @click="openLogoutPopup"/>
    </div>
    <Modal class="logout-modal" v-if="isModalVisible">
        Möchten Sie sich wirklich abmelden?
        <div class="button-container">
            <Button text="Ja" @click="$router.push('logout')"/>
            <Button text="Nein" @click="hideModal"/>
        </div>
    </Modal>
</template>

<script>
import Button from "@/views/components/ui/Button"
import SidebarItem from "@/views/components/ui/SidebarItem"
import Modal from "@/views/components/ui/Modal"

export default {
    name: 'Sidebar',
    components: {SidebarItem, Button, Modal},
    data() {
        return {
            isSidebarActive: false,
            isModalVisible: false
        }
    },
    methods: {
        closeSidebar() {
            this.$store.commit('TOGGLE_SIDEBAR')
            this.isSidebarActive = this.$store.getters.getSidebarStatus
        },
        hideModal() {
            this.isModalVisible = false
        },
        openLogoutPopup() {
            this.closeSidebar()
            this.isModalVisible = true
        },
        showCookieLayer() {
            this.closeSidebar()
            this.$store.commit('TOGGLE_COOKIE_LAYER', true)
        }
    },
    watch: {
        "$store.state.sidebarActive"(bool) {
            this.isSidebarActive = bool
        }
    }
}
</script>

<style lang="scss">
.sidebar-container {
    position: fixed;
    top: 0;
    transform: translateX(100%);
    right: 0;
    height: 100%;
    width: 80%;
    border-radius: 15px 0 0 15px;
    background-color: $secondary;
    box-shadow: 1px 2px 3px $primary;
    z-index: 100;
    padding: 1rem;
    transition: transform .5s ease-in-out;
    will-change: transform;

    @media all and (min-width: $tablet) {
        width: 250px;
    }

    &.active {
        transform: none;
        transition: transform .5s ease-in-out;
    }

    .sidebar-header {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $grey-dark;

        .logo {
            margin-right: auto;
            max-height: 40px;
        }

        .delete {
            background-color: $secondary;

            &:before,
            &:after {
                background-color: $primary;
            }

            &:before {
                width: 80%;
            }

            &:after {
                height: 80%;
            }
        }
    }

    .logout-button {
        margin-top: auto;
        width: 100%;
    }
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(10, 10, 10, 0.86);
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s ease-in-out;

    &.active {
        pointer-events: auto;
        opacity: 1;
        transition: opacity .5s ease-in-out;
    }
}

.logout-modal {
    text-align: center;

    .button-container {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;

        button {
            width: 45%;
        }
    }
}
</style>