<template>
    <div class="register container is-fluid">
        <RegisterDestination v-if="actualStep===1"/>
        <RegisterForm v-if="actualStep===2"/>
        <RegisterOverview v-if="actualStep===3"/>
        <RegisterSuccess v-if="actualStep===4"/>
    </div>
</template>

<script>
import RegisterDestination from "@/views/components/ui/RegisterDestination";
import RegisterForm from "@/views/components/ui/RegisterForm";
import RegisterOverview from "@/views/components/ui/RegisterOverview";
import RegisterSuccess from "@/views/components/ui/RegisterSuccess";

export default {
    name: 'Register',
    components: {
        RegisterDestination,
        RegisterForm,
        RegisterOverview,
        RegisterSuccess
    },
    data() {
        return {
            actualStep: 1
        }
    },
    methods: {
        addStep() {
            this.$store.commit('ADD_STEP')
        },
        reduceStep() {
            this.$store.commit('REDUCE_STEP')
        }
    },
    beforeMount() {
        if (this.$store.getters.getActualStep === undefined) {
            this.$store.commit('SET_STEP', 1)
            this.actualStep = 1
            return
        }
        this.actualStep = this.$store.getters.getActualStep
        if (this.$store.getters.getActualStep === 4) {
            this.$store.commit('REMOVE_STEP')
        }
    },
    watch: {
        "$store.state.actualStep"(actualStep) {
            this.actualStep = actualStep
        }
    }
}
</script>

<style lang="scss">
.register {
    h1 {
        font-size: $fontsize-headline6;
        font-weight: $font-weight-bold;
        padding: 1rem 0;
    }
    p.subheadline {
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        padding: 1rem 0;
    }
    p {
        padding-bottom: 1rem;
    }
    .subnote-send {
        padding: 1rem 0;
    }
    label.small {
        font-size: 11px;
        color: $dark-grey;
    }
    input {
        margin-bottom: .5rem;
    }
    label a {
        font-weight: $font-weight-black;
    }
    span {
        display: inline
    }
    .subnote {
        font-size: $font-size-xxsmall;
    }
}
</style>