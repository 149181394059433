<template>
    <div class="menu-list-item is-flex is-flex-direction-column"
         :class="{'inactive':meal.locked||checkDeadline(meal.deadlineOrder), 'filtered-out':mustBeFilteredOut}">
        <div class="menu-list-item__header" v-if="!isCart">
            <span class="menu-list-item__header--category" :class="{'inactive':meal.locked||checkDeadline(meal.deadlineOrder)}">
                {{meal.categoryName}}
            </span>
            <span class="menu-list-item__header--tags" v-for="tag in meal.tags" :key="tag">
                | {{ tag }}
            </span>
        </div>
        <h3 class="menu-list-item__name"><span v-if="isCart">{{ meal.categoryShortName }}</span> {{ meal.name }}</h3>
        <span class="menu-list-item__description">{{ meal.description }}</span>
        <span class="menu-list-item__allergens" v-if="!isCart" @click="showModal(meal)" role="button">Allergene und Zusatzstoffe</span>
        <div class="amount-container is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap">
            <Price :price="meal.price"/>
            <QuantityButton :meal="meal"/>
        </div>
        <div v-if="isCart" class="meal-cart-summary is-flex is-justify-content-space-between is-align-items-baseline">
            <MenuQuantity :id="meal.id"/>
            <TotalPrice :id="meal.id" :price="meal.price"/>
        </div>
    </div>
</template>

<script>
import Price from '@/views/components/ui/Price'
import QuantityButton from "@/views/components/ui/QuantityButton";
import MenuQuantity from "@/views/components/ui/MenuQuantity";
import TotalPrice from "@/views/components/ui/TotalPrice";
import {parseISO, isPast} from 'date-fns'

export default {
    name: 'MenuListItem',
    components: {
        Price,
        QuantityButton,
        MenuQuantity,
        TotalPrice
    },
    data() {
        return {
            mustBeFilteredOut: false,
            mealCategories: []
        }
    },
    props: [
        'meal',
        'isActive',
        'isCart',
        'filtered'
    ],
    methods: {
        isFilterActive() {
            const activeFilters = this.$store.getters.getActiveFilters

            if (activeFilters === null || activeFilters.length === 0) {
                this.mustBeFilteredOut = false
                return
            }

            if (this.categoriesAreIn(activeFilters)) {
                this.mustBeFilteredOut = false
                return
            }

            this.mustBeFilteredOut = true
        },
        categoriesAreIn(activeFilters) {
            return activeFilters.every(filter => this.mealCategories.includes(filter));
        },
        showModal(meal) {
            this.$store.commit('SET_SELECTED_MEAL', meal)
            this.$store.commit('TOGGLE_MODAL', true)
        },
        checkDeadline(deadline) {
            return isPast(parseISO(deadline))
        }
    },
    beforeMount() {
        this.isFilterActive()
    },
    created() {
        this.mealCategories = this.meal.matchingFilters
        this.isFilterActive()
        this.subscribe = this.$store.subscribe((mutation) => {
            if (mutation.type === 'SET_ACTIVE_FILTER') {
                this.isFilterActive()
            }
        })
    }
}
</script>

<style lang="scss" scoped>
.menu-list-item {
    background-color: $secondary;
    border-radius: 15px;
    padding: 1.5rem 1rem;
    margin-bottom: 1.5rem;

    .amount-container {
        position: relative;
    }

    &.filtered-out {
        display: none !important;
    }


    &.inactive {
        color: $dark-grey;
    }

    &__header {
        font-size: $font-size-small;
        margin-bottom: 1em;

        &--category {
            background-color: $primary;
            color: $secondary;
            border-radius: 23px;
            padding: .3rem .6rem;
            font-weight: $font-weight-bold;

            &.inactive {
                background-color: $dark-grey;
            }
        }

        span {
            margin-right: .25rem;
        }
    }

    &__name {
        font-weight: $font-weight-black;
        font-size: $fontsize-headline7;
    }

    &__description {
        font-size: $font-size-small;
        margin-bottom: 1em;
    }

    &__allergens {
        font-size: $font-size-small;
        color: $dark-grey;
        cursor: pointer;
        margin-bottom: 0.8rem;
    }
}

.vcp {
    &.grey-container {
        &.inactive {
            .menu-list-item__header--category {
                background-color: $dark-grey;
            }
        }
    }
}

@media all and (min-width: $desktop) {
    .desktop-list {
        .menu-list-item__header{
            height: 45px;
        }
        span.menu-list-item__description {
            height: 130px;
        }

        h3.menu-list-item__name {
            height: 60px;
        }
    }
    .desktop {
        .menu-list-item {
            float: left;
            width: 49%;
            margin-left: .5%;
            margin-right: .5%;

            @media all and (min-width: $widescreen) {
                width: 32%;
                margin-left: .5%;
                margin-right: .5%;
            }

            @media all and (min-width: $fullhd) {
                width: 24%;
                margin-left: .5%;
                margin-right: .5%;
            }

            span.menu-list-item__description {
                height: 90px;
            }

            h3.menu-list-item__name {
                height: 60px;
            }
        }
    }
}
</style>
